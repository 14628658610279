import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {RequestActivationLog} from "../models/RequestActivationLog";

export const RequestActivationLogService = {
    getByRequestActivation: async (requestActivationId: string, params?: GetByRequestActivationFilterSchema): Promise<ApiResponse<RequestActivationLog[]>>  => {
        try {
            const response = await MainBackendAxios.post(`/request-activation-log/get-by-request-activation/${requestActivationId}`, params);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as RequestActivationLog[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    createClientNote: async (requestActivationId: string, params: CreateRequestActivationLogClientNoteRequestDto): Promise<ApiResponse<{ createdId: number }>>  => {
        try {
            const response = await MainBackendAxios.post(`/request-activation-log/create-client-note/${requestActivationId}`, params);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as { createdId: number },
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    createBusinessChainNote: async (requestActivationId: string, params: CreateRequestActivationLogBusinessChainNoteRequestDto): Promise<ApiResponse<{ createdId: number }>>  => {
        try {
            const response = await MainBackendAxios.post(`/request-activation-log/create-business-chain-note/${requestActivationId}`, params);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as { createdId: number },
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};

export interface CreateRequestActivationLogClientNoteRequestDto {
    content: string;
}

export interface CreateRequestActivationLogBusinessChainNoteRequestDto {
    content: string;
}

export interface GetByRequestActivationFilterSchema {
    isRealStatus?: boolean;
    requestActivationStatusId?: string;
}
