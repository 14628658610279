import React from 'react';
import Layout from "../../../components/Layout/Layout";
import {FormOutlined} from "@ant-design/icons";
import {Card} from "antd";

import './ActivationTurns.scss';

function ActivationTurns() {
    return (
        <Layout breadcrumb={[
            { title: <span><FormOutlined/> Activaciones</span> },
            { title: 'Turnos' }
        ]}>
            <Card>
                <p>
                    Turnos
                </p>
            </Card>
        </Layout>
    );
}

export default ActivationTurns;
