import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, message, Modal, Row, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import {RequestActivation} from "../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../config/ThemeDashboardLayoutConfig";
import {RequestActivationService} from "../../../services/RequestActivationService";

interface RefusedStatusModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    selectedRequestActivation: RequestActivation;
    fetchTable: (currentPage?: number) => void;
    detailCallback?: () => void;
}

function RefusedStatusModal({ isOpen, handleModalCancel, selectedRequestActivation, fetchTable, detailCallback }: RefusedStatusModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const themeConfig = useThemeLayoutConfig();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
        if(isOpen) {
            form.resetFields();
        }
    }, [isOpen])

    const onFinish = async (values: any) => {
        if(!loading) {
            const reason = values.reason;

            setLoading(true);

            const response = await RequestActivationService.updateStatusToRefused(selectedRequestActivation.id, reason);

            if(response.success) {
                messageApi.success(<span>Se rechazó la solicitud con N° <b>{selectedRequestActivation.id}</b> de manera satisfactoria.</span>, 3.5);
                await fetchTable();
                handleModalCancel();
                setLoading(false);
                if(detailCallback) {
                    detailCallback();
                }
            }else {
                const error = response.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar rechazar la solicitud de activación, por favor inténtalo nuevamente.', 3.5);
                setLoading(false);
            }
        }
    }

    return (
        <>
            { contextHolder }

            <Modal
                title={<span>Solicitud N°:  <span style={{ color: themeConfig.token?.colorPrimary }} >{selectedRequestActivation.id}</span></span>}
                open={isOpen}
                onCancel={handleModalCancel}
                okText="Rechazar"
                maskClosable={false}
                destroyOnClose
                footer={null}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="reason"
                                label="Motivo de rechazo"
                                rules={[{ required: true, message: 'Debes de ingresar el motivo de rechazo.' }]}
                            >
                                <TextArea
                                    placeholder="Ingrese el motivo del rechazo"
                                    rows={4}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Rechazar solicitud</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default RefusedStatusModal;
