import MainBackendAxios from '../utils/MainBackendAxios';
import {ApiResponse} from "../models/ApiResponse";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {User} from "../models/User";
import {getFormattedUserData} from "../utils/AuthUtils";
import {PaginationData} from "../models/PaginationData";

export const UserService = {
  sendPasswordRecovery: async (username: string): Promise<ApiResponse<{ userId: number, expiresInMs: number }>> => {
    try {
      const response = await MainBackendAxios.post(`/user/send-recovery-token-by-username`, { username: username });

      return {
        success: true,
        status: response.status,
        data: response.data.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  sendNewActivationCode: async (userId: number): Promise<ApiResponse<{ executedAction: boolean, userId: number, expiredCodeAt: Date }>> => {
    try {
      const response = await MainBackendAxios.post(`/user/send-new-activation-code/${userId}`);

      return {
        success: true,
        status: response.status,
        data: response.data.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  verifyRecoveryToken: async (token: string): Promise<ApiResponse<{ userId: number, email: string }>> => {
    try {
      const response = await MainBackendAxios.post(`/user/verify-recovery-token`, { recoveryToken: token });

      return {
        success: true,
        status: response.status,
        data: response.data.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  updatePasswordByRecoveryToken: async (token: string, newPassword: string): Promise<ApiResponse<{ executedAction: boolean, user: User }>> => {
    try {
      const response = await MainBackendAxios.post(`/user/update-password-by-recovery-token`, { recoveryToken: token, newPassword });
      const userData = response?.data?.data.user;

      return {
        success: true,
        status: response.status,
        data: {
          executedAction: response.data.executedAction,
          user: getFormattedUserData(userData)
        },
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  verifyUser: async (userId: number, activationCode: string): Promise<ApiResponse<{ executedAction: boolean, user: User }>> => {
    try {
      const response = await MainBackendAxios.post(`/user/verify-by-activation-code/${userId}`, { activationCode });
      const userData = response?.data?.data.user;

      return {
        success: true,
        status: response.status,
        data: {
          executedAction: response.data.executedAction,
          user: getFormattedUserData(userData)
        },
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  getAllByFiltersAndPaginate: async (bodyParams: GetAllUsersByFiltersAndPaginateRequestDto): Promise<ApiResponse<PaginationData<User>>> => {
    try {
      const response = await MainBackendAxios.post(`/user/get-all-by-filters-and-paginate`, bodyParams);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  findById: async (id: number): Promise<ApiResponse<User>> => {
    try {
      const response = await MainBackendAxios.get(`/user/find-by-id/${id}`);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  createRegularAccount: async (body: CreateRegularAccountRequestDto): Promise<ApiResponse<{ createdId: number }>> => {
    try {
      const response = await MainBackendAxios.post(`/user/create-regular-account`, body);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  setAsInactive: async (id: number): Promise<ApiResponse<{ executedAction: boolean }>> => {
    try {
      const response = await MainBackendAxios.patch(`/user/set-as-inactive/${id}`);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  setAsActive: async (id: number): Promise<ApiResponse<{ executedAction: boolean }>> => {
    try {
      const response = await MainBackendAxios.patch(`/user/set-as-active/${id}`);

      return {
        success: true,
        status: response.status,
        data: response?.data?.data,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};

export interface GetAllUsersByFiltersAndPaginateRequestDto {
  page: number;
  pageSize: number;
  sortField?: string;
  sortOrder?: string;
  term?: string;
  roles?: string[];
  isDisabled?: boolean;
}

export interface CreateRegularAccountRequestDto {
  email: string;
  name: string;
  lastname: string;
  dialCode: string;
  phone: string;
  companyId: number;
  roleId: number;
  brandIds: number[];
  storeIds: number[];
}
