import React, {useEffect, useState} from 'react';
import {Menu} from "../../../../models/Menu";
import {Alert, Button, Col, Divider, Form, message, Modal, Row, Tree, TreeDataNode, TreeProps} from "antd";
import {MenuService} from "../../../../services/MenuService";
import {Company} from "../../../../models/Company";
import {AlignLeftOutlined} from "@ant-design/icons";
import DynamicIcon from "../../../../components/DynamicIcon/DynamicIcon";

interface MenuDetailModalProps {
    client: Company;
    isOpen: boolean;
    handleModalCancel: () => void;
    menus: Menu[];
    submit: (company: Company, keys: string[]) => void;
}

const buildTree = (data: Menu[]) => {
    const map:any = {};
    const tree: TreeDataNode[] = [];

    data.forEach(item => {
        map[item.code] = {
            title: item.title,
            key: item.code,
            icon: item.icon ? <DynamicIcon type={item.icon.trim()}/> : undefined,
            children: []
        };
    });

    data.forEach(item => {
        if (item.parentMenuCode) {
            map[item.parentMenuCode].children.push(map[item.code]);
        } else {
            tree.push(map[item.code]);
        }
    });

    return tree;
};


function MenuDetailModal({ client, isOpen, handleModalCancel, menus, submit }: MenuDetailModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const treeData: TreeDataNode[] = [
        {
            title: `${window.location.origin}`,
            key: 'root',
            children: buildTree(menus)
        }
    ]

    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        if(isOpen) {
            fetchData();
        }else {
            setLoading(false);
            setCheckedKeys([]);
        }
    }, [isOpen]);

    const fetchData = async () => {
        setLoading(true);

        const response = await MenuService.getAvailableMenusByCompany(client.id);

        if(response.success) {
            fetchMenus(response.data);
            setLoading(false);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los menus asociados al cliente, por favor inténtalo nuevamente.', 3.5);
            handleModalCancel();
        }
    }

    const onFinish = async (values: any) => {

        setLoading(true);

        const selectedMenus = menus.filter((record) => {
            return checkedKeys.includes(record.code);
        });

        const childrens = selectedMenus.filter((record) => {
            return record.menuTypeId !== 'PARENT_MENU' && record.parentMenuCode !== null;
        });

        const stack: { parentCode: string, children: Menu[] }[] = [];

        for (const record of childrens) {
            const checkIdx = stack.findIndex((stackRow) => {
                return record.parentMenuCode === stackRow.parentCode;
            });

            if(checkIdx === -1) {
                stack.push({
                    parentCode: record.parentMenuCode!,
                    children: [ record ]
                })
            }else {
                stack[checkIdx].children.push(record);
            }
        }

        for (const stackRow of stack) {
            const parent = menus.find((record) => {
                return record.code === stackRow.parentCode;
            });

            if(parent) {
                const check = selectedMenus.find((record) => {
                    return stackRow.parentCode === record.code;
                });

                if(!check && stackRow.children.length > 0) {
                    selectedMenus.push(parent);
                }
            }
        }

        const keys = selectedMenus.map((record) => {
            return record.code;
        });

        // @ts-ignore
        await submit(client, [...new Set(keys)]);

        setLoading(false);
    }

    const onCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
        console.log('onCheck', checkedKeysValue);
        setCheckedKeys(checkedKeysValue as React.Key[]);
    };

    const fetchMenus = (currentMenus: Menu[]) => {
        let localCheckedKeys: React.Key[] = [];

        // const parents = currentMenus.filter((record) => {
        //     return record.menuTypeId === 'PARENT_MENU' && record.parentMenuCode === null;
        // });

        const childrens = currentMenus.filter((record) => {
            return record.menuTypeId !== 'PARENT_MENU' && record.parentMenuCode !== null;
        });

        const menusWithoutChildrens = currentMenus.filter((record) => {
            return record.menuTypeId !== 'PARENT_MENU' && record.parentMenuCode === null;
        });

        localCheckedKeys = localCheckedKeys.concat(menusWithoutChildrens.map((record) => {
            return record.code;
        }));

        localCheckedKeys = localCheckedKeys.concat(childrens.map((record) => {
            return record.code;
        }));

        setCheckedKeys(localCheckedKeys);
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={<><AlignLeftOutlined/> Menus - {client.name}</>}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={640}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24} style={{ marginBottom: '18px' }}>
                        <Col xs={24}>
                            <Alert
                                message="Seleccione los menús que desea habilitar para el cliente. Cada menú seleccionado será visible y accesible en la aplicación web de captadores."
                                type="info"
                                showIcon
                            />
                        </Col>
                    </Row>

                    <Row gutter={24} style={{ marginBottom: '18px' }}>
                        <Col xs={24} lg={24}>
                            <Tree
                                disabled={loading}
                                checkable
                                onCheck={onCheck}
                                selectable={false}
                                defaultExpandAll
                                checkedKeys={checkedKeys}
                                treeData={treeData}
                                showIcon
                            />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default MenuDetailModal;
