import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Company} from "../models/Company";
import {PaginationData} from "../models/PaginationData";
import {GenericCreateResponseDto} from "../models/dtos/GenericCreateResponseDto";
import {GenericUpdateOrDeleteResponseDto} from "../models/dtos/GenericUpdateOrDeleteResponseDto";

export const CompanyService = {
    updateCustomConfigByCurrentUser: async (params: UpdateCustomConfigByCurrentUserRequestDto, file?: File): Promise<ApiResponse<{ executedAction: boolean }>>  => {
        try {
            const formData = new FormData();
            formData.append('primaryColor', params.primaryColor);
            formData.append('secondaryColor', params.secondaryColor);
            formData.append('itemSelectedBg', params.itemSelectedBg);
            formData.append('itemSelectedColor', params.itemSelectedColor);
            formData.append('itemActiveBg', params.itemActiveBg);

            if(params.logoHeight) {
                formData.append('logoHeight', `${params.logoHeight}`);
            }

            if(file) {
                formData.append('file', file);
            }

            const response = await MainBackendAxios.put(`/company/update-custom-config-by-current-user`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAll: async (): Promise<ApiResponse<Company[]>> => {
        try {
            const response = await MainBackendAxios.get(`/company/get-all`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    findById: async (id: number): Promise<ApiResponse<Company>> => {
        try {
            const response = await MainBackendAxios.get(`/company/find-by-id/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAllByClientProfileAndFilters: async (body: GetCompanyByCountryFiltersRequestDto): Promise<ApiResponse<PaginationData<Company>>> => {
        try {
            const response = await MainBackendAxios.post(`/company/get-by-client-profile-filters`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    createByClientProfile: async (body: CreateWithClientProfileRequestDto, file: File | null): Promise<ApiResponse<GenericCreateResponseDto>> => {
        try {
            const formData = new FormData();
            formData.append('name', body.name);
            formData.append('countryCode', body.countryCode);
            formData.append('activationRequestPrefix', body.activationRequestPrefix);
            formData.append('taxRate', `${body.taxRate}`);
            formData.append('currencyCode', body.currencyCode);
            formData.append('description', body.description || '');

            if(file) {
                formData.append('file', file);
            }

            const response = await MainBackendAxios.post(`/company/create-with-client-profile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    updateByClientProfile: async (id: number, body: UpdateWithClientProfileRequestDto, file?: File | null): Promise<ApiResponse<GenericUpdateOrDeleteResponseDto>> => {
        try {
            const formData = new FormData();
            formData.append('name', body.name);
            formData.append('taxRate', `${body.taxRate}`);
            formData.append('currencyCode', body.currencyCode);
            formData.append('description', body.description || '');
            formData.append('uploadLogo', body.uploadLogo ? 'true' : 'false');

            if(body.uploadLogo && file) {
                formData.append('file', file);
            }

            const response = await MainBackendAxios.put(`/company/update-with-client-profile/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};

export interface UpdateCustomConfigByCurrentUserRequestDto {
    primaryColor: string;
    secondaryColor: string;
    itemSelectedBg: string;
    itemActiveBg: string;
    itemSelectedColor: string;
    logoHeight?: number;
}

export interface GetCompanyByCountryFiltersRequestDto {
    term?: string;
    page: number;
    pageSize?: number;
    sortOrder?: 'descend' | 'ascend',
    sortField?: string;
}

export interface CreateWithClientProfileRequestDto {
    name: string;
    countryCode: string;
    activationRequestPrefix: string;
    taxRate: number;
    currencyCode: string;
    description?: string | null;
}

export interface UpdateWithClientProfileRequestDto {
    name: string;
    taxRate: number;
    currencyCode: string;
    uploadLogo: boolean;
    description?: string | null;
}
