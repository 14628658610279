import React, {useState} from 'react';
import {
    Alert,
    Button,
    Checkbox,
    CheckboxProps,
    Col,
    Descriptions,
    DescriptionsProps,
    Drawer,
    FormInstance,
    Row
} from "antd";
import {useThemeLayoutConfig} from "../../../../config/ThemeDashboardLayoutConfig";
import {User} from "../../../../models/User";
import {useSelector} from "react-redux";
import {ActivationContainerSchema, ActivationSchema} from "../ActivationRequest";
import {Store} from "../../../../models/Store";

interface ResumeDrawerProps {
    isOpen: boolean;
    closeDrawer: () => void;
    activations: ActivationSchema[];
    stores: Store[];
    getSubTotalPerRow: (row: ActivationSchema, fixed: boolean) => string | number;
    loading?: boolean;
    submit: (currencyCode: string, taxRate: number, groupByBusinessChains: ActivationContainerSchema[]) => void;
    form: FormInstance<any>;
}

function ResumeDrawer({ isOpen, closeDrawer, activations, getSubTotalPerRow, loading, submit, stores, form }: ResumeDrawerProps) {
    const themeConfig = useThemeLayoutConfig();
    const authUser: User = useSelector((state: any) => state.auth);
    const [terms, setTerms] = useState(false);

    const getPercentLabel = () => {
        return (authUser.company.taxRate * 100).toFixed(2);
    }

    const onChange: CheckboxProps['onChange'] = (e) => {
        setTerms(e.target.checked);
    };

    const getData = (): DescriptionsProps['items'] => {
        const groupByBusinessChains = getGroupByBusinessChains();

        return [
            ...(groupByBusinessChains.map((record, idx) => {
                return {
                    key:  `ST-${record.id}`,
                    label: `Solicitud N° ${idx + 1}`,
                    children: <span style={{ float: 'right' }}>{record.subtotal.toFixed(2)} {authUser.company.currency.code}</span>,
                    span: 3
                }
            })),
            {
                key: 'SUBTOTAL',
                label: <span style={{ fontWeight: 500 }}>Subtotal</span>,
                children: <span style={{ float: 'right', fontWeight: 500 }}>{ getSubTotal(groupByBusinessChains).toFixed(2) } {authUser.company.currency.code}</span>,
                span: 3
            },
            {
                key: 'TAXES',
                label: <span style={{ fontWeight: 500 }}>Impuesto ({getPercentLabel()}%)</span>,
                children: <span style={{ float: 'right', fontWeight: 500 }}>{ getTaxes(groupByBusinessChains).toFixed(2) } {authUser.company.currency.code}</span>,
                span: 3
            },
            {
                key: 'TOTAL',
                label: <span style={{ fontWeight: 500, color: themeConfig.token!.colorPrimary }}>Total</span>,
                children: <span style={{ float: 'right', color: themeConfig.token!.colorPrimary, fontWeight: 500 }}>{getTotal(groupByBusinessChains).toFixed(2)} {authUser.company.currency.code}</span>,
                span: 3
            }
        ];
    }

    const getSubTotalByBusinessChain = (groupBusinessChain: ActivationContainerSchema) => {
        return  groupBusinessChain.activations.reduce((accumulator, currentValue) => {
            return accumulator + (getSubTotalPerRow(currentValue, false) as number);
        }, 0);
    }

    const getGroupByBusinessChains = (): ActivationContainerSchema[] => {
        const data: ActivationContainerSchema[] = [];


        for (const activation of activations) {
            const store = stores.find((store) => {
                return store.id === activation.storeId;
            }) as Store;

            const businessChainIdx = data.findIndex((record) => {
                return record.id === store.businessChain!.id;
            });

            if(businessChainIdx !== -1) {
                const localActivations = [...data[businessChainIdx].activations];
                localActivations.push(activation);
                data[businessChainIdx].activations = localActivations;

                const subtotal = getSubTotalByBusinessChain(data[businessChainIdx]);
                const taxes = parseFloat((subtotal * authUser.company.taxRate).toFixed(2));
                const total = subtotal + taxes;
                data[businessChainIdx].subtotal = subtotal;
                data[businessChainIdx].taxes = taxes;
                data[businessChainIdx].total = total;
            }else {
                data.push({
                    id: store.businessChain!.id,
                    name: store.businessChain!.name,
                    activations: [
                        activation
                    ],
                    subtotal: getSubTotalPerRow(activation, false) as number,
                    taxes: parseFloat((getSubTotalPerRow(activation, false) as number * authUser.company.taxRate).toFixed(2)),
                    total: ((getSubTotalPerRow(activation, false) as number) + parseFloat((getSubTotalPerRow(activation, false) as number * authUser.company.taxRate).toFixed(2)))
                });
            }
        }


        return data;
    }

    const getSubTotal = (groupByBusinessChains: ActivationContainerSchema[]) => {
        return groupByBusinessChains.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.subtotal;
        }, 0);
    }

    const getTaxes = (groupByBusinessChains: ActivationContainerSchema[]) => {
        return groupByBusinessChains.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.taxes;
        }, 0);
    }

    const getTotal = (groupByBusinessChains: ActivationContainerSchema[]) => {
        return groupByBusinessChains.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.total;
        }, 0);
    }

    const onFinish = () => {
        if(!loading) {
            submit(authUser.company.currencyCode, authUser.company.taxRate, getGroupByBusinessChains());
        }
    }

    return (
        <Drawer
            closable
            destroyOnClose
            title="Resumen de solicitud"
            placement="right"
            open={isOpen}
            onClose={() => {
                if(!loading) {
                    closeDrawer();
                }
            }}
            width={500}
            maskClosable={false}
        >
            <div className="activation-request-totalized-container">

                <Descriptions
                    bordered
                    size="small"
                    items={getData()}
                />

                <Row gutter={24} style={{ marginTop: '18px' }}>
                    <Col span={24}>
                        <Checkbox checked={terms} onChange={onChange}>Leí y acepto los <a target="_blank" style={{ fontWeight: 500, color: themeConfig.token?.colorPrimary }}>Términos y condiciones</a></Checkbox>
                    </Col>
                </Row>

                <Row gutter={24} style={{ marginTop: '18px' }}>
                    <Col span={24}>
                        <Button loading={loading} onClick={onFinish} disabled={!terms} type="primary" block>{ getGroupByBusinessChains().length === 1 ? 'Solicitar Activación' : 'Solicitar activaciones' }</Button>
                    </Col>
                </Row>

                {
                    form.getFieldValue('requiresTasting') === 1  && (
                        <Row gutter={24} style={{ marginTop: '18px' }}>
                            <Col span={24}>
                                <Alert message="Cualquier valor adicional relacionado con actividades de degustación deberá ser proporcionado por su equipo o gestionado a través de la agencia." type="info" showIcon />
                            </Col>
                        </Row>
                    )
                }
            </div>
        </Drawer>
    );
}

export default ResumeDrawer;
