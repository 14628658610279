import React from 'react';
import {Button, Divider, Modal} from "antd";

interface RequestActivationSuccessModalProps {
    isOpen: boolean;
    handleModalOk: () => void;
    message: React.ReactNode | string;
}

function RequestActivationSuccessModal({ isOpen, handleModalOk, message }: RequestActivationSuccessModalProps) {
    return (
        <Modal
            title="Solicitud procesada"
            open={isOpen}
            onOk={() => { handleModalOk(); }}
            onCancel={() => { handleModalOk(); }}
            footer={false}
            maskClosable={false}
            destroyOnClose
            width={450}
        >
            <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

            {message}

            <Button  onClick={() => { handleModalOk(); }} style={{ marginTop: '18px' }} type="primary" block>Ir a historial de solicitudes</Button>
        </Modal>
    );
}

export default RequestActivationSuccessModal;
