import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {CompanyContact} from "../models/CompanyContact";
import {GenericCreateResponseDto} from "../models/dtos/GenericCreateResponseDto";
import {GenericUpdateOrDeleteResponseDto} from "../models/dtos/GenericUpdateOrDeleteResponseDto";

export const CompanyContactService = {
    getByCompany: async (companyId: number): Promise<ApiResponse<CompanyContact[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/company-contact/get-by-company/${companyId}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as CompanyContact[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    findById: async (id: number): Promise<ApiResponse<CompanyContact>>  => {
        try {
            const response = await MainBackendAxios.get(`/company-contact/find-by-id/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as CompanyContact,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    create: async (body: CreateCompanyContactRequestDto): Promise<ApiResponse<GenericCreateResponseDto>>  => {
        try {
            body.description = body.description || null;
            const response = await MainBackendAxios.post(`/company-contact/create`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    update: async (id: number, body: UpdateCompanyContactRequestDto): Promise<ApiResponse<GenericUpdateOrDeleteResponseDto>>  => {
        try {
            body.description = body.description || null;
            const response = await MainBackendAxios.put(`/company-contact/update/${id}`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    delete: async (id: number): Promise<ApiResponse<GenericUpdateOrDeleteResponseDto>>  => {
        try {
            const response = await MainBackendAxios.delete(`/company-contact/delete/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};

interface CreateCompanyContactRequestDto {
    companyId: number;
    name: string;
    lastname: string;
    description?: string | null;
    email: string;
    dialCode: string;
    phoneNumber: string;
    isDefaultContact: boolean;
}

interface UpdateCompanyContactRequestDto {
    name: string;
    lastname: string;
    description: string | null;
    email: string;
    dialCode: string;
    phoneNumber: string;
    isDefaultContact: boolean;
}
