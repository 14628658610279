import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row} from "antd";
import TextArea from "antd/es/input/TextArea";
import PhoneInput from "antd-phone-input";
import {Country} from "../../../../../models/Country";

interface NewContactModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    parentId: number;
    countryCode: string;
    countries: Country[];
    submit: (companyId: number, name: string, lastname: string, email: string, dialCode: string, phoneNumber: string, description: string, isDefaultContact: boolean) => void;
}

function NewContactModal({isOpen, handleModalCancel, parentId, countryCode, countries, submit}: NewContactModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(isOpen) {
            form.resetFields();

            const country = findCountryByCode(countryCode);
            if(country) {
                form.setFieldValue('phone', {...form.getFieldValue('phone'), countryCode: parseInt(country.dialCode)})
            }
        }
    }, [isOpen]);

    const onFinish = async (values: any) => {
        setLoading(true);
        await submit(
            parentId,
            values.name,
            values.lastname,
            values.email,
            `+${values.phone.countryCode}`,
            `${values.phone.areaCode}${values.phone.phoneNumber}`,
            values.description || '',
            !!values.isDefaultContact
        );
        setLoading(false);
    }

    const findCountryByCode = (code: string) => {
        return countries.find((record) => {
            return record.code === code;
        });
    }

    return (
        <>
            {contextHolder}

            <Modal
                title="Crear contacto"
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={640}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="name"
                                label="Nombres"
                                rules={[{ required: true, message: 'Debes de ingresar los nombres.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los nombres"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="lastname"
                                label="Apellidos"
                                rules={[{ required: true, message: 'Debes de ingresar los apellidos.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los apellidos" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="email"
                                label="Correo electrónico"
                                rules={[
                                    { required: true, message: 'Debes ingresar el correo electrónico.' },
                                    {
                                        type: 'email',
                                        message: 'El correo electrónico no es válido.'
                                    }
                                ]}
                            >
                                <Input
                                    type="text"
                                    disabled={loading}
                                    placeholder="Ingrese el correo electrónico"
                                    addonAfter={'@'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="phone"
                                label="Teléfono"
                                rules={[
                                    { required: true, message: 'El teléfono es requerido' },
                                    {
                                        validator: (_, value) => {
                                            if (value && value.valid()) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Debes ingresar un número de teléfono válido.'));
                                        },
                                    },
                                ]}
                            >
                                <PhoneInput enableSearch searchNotFound="No se encontró el país" searchPlaceholder="Buscar país" allowClear placeholder="Ingrese el teléfono" disabled={loading}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="description"
                                label="Descripción"
                            >
                                <TextArea rows={2} placeholder="Ingrese una descripción" disabled={loading}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="isDefaultContact"
                                valuePropName="checked"
                            >
                                <Checkbox disabled={loading}>Contacto principal</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewContactModal;
