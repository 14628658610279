import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Menu} from "../models/Menu";

export const MenuService = {
    getAll: async (): Promise<ApiResponse<Menu[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/menu/get-all`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as Menu[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAccessibleMenusByUser: async (userId: number): Promise<ApiResponse<Menu[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/menu/get-accessible-menus-by-user/${userId}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as Menu[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAvailableMenusByCompany: async (companyId: number): Promise<ApiResponse<Menu[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/menu/get-available-menus-by-company/${companyId}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as Menu[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    addMenusToCompany: async (body: AddMenusToCompanySchema): Promise<ApiResponse<{ createdRows: number }>>  => {
        try {
            const response = await MainBackendAxios.post(`/menu/add-menus-to-company`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};

export interface AddMenusToCompanySchema {
    companyId: number;
    codes: string[];
}
