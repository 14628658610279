import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Input, message, Modal, Row, Tag} from "antd";
import {Country} from "../../../models/Country";
import {User} from "../../../models/User";
import {UserService} from "../../../services/UserService";
import {
    BankOutlined,
    FlagOutlined,
    StarOutlined, TagsOutlined,
    TeamOutlined
} from "@ant-design/icons";
import PhoneInput from "antd-phone-input";
import {ApplicationProfileType} from "../../../models/ApplicationProfileType";

interface UserDetailModalProps {
    isOpen: boolean;
    selectedUserId: number;
    handleModalCancel: () => void;
    countries: Country[];
    applicationProfileTypes: ApplicationProfileType[];
}

function UserDetailModal({ isOpen, selectedUserId, handleModalCancel, countries, applicationProfileTypes }: UserDetailModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState<User>();

    useEffect(() => {
        if(!isOpen) {
            form.resetFields();
            setCurrentUser(undefined);
        }else {
            fetchUser();
        }
    }, [isOpen]);

    const fetchUser = async () => {
        setLoading(true);

        const userResponse = await UserService.findById(selectedUserId);

        if(userResponse.success) {
            setCurrentUser(userResponse.data);

            form.setFieldValue('name', userResponse.data.name);
            form.setFieldValue('lastname', userResponse.data.lastname);
            form.setFieldValue('email', userResponse.data.email);
            let newPhone = {...form.getFieldValue('phone'), countryCode: parseInt(userResponse.data.dialCode), areaCode: null, phoneNumber: null};

            if(userResponse.data.phone) {
                newPhone.areaCode = userResponse.data.phone;
                // newPhone.phoneNumber = userResponse.data.phone;
            }

            form.setFieldValue('phone', newPhone);

            setLoading(false);
        }else {
            const error = userResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos del usuario, por favor inténtalo nuevamente.', 3.5);
        }
    }

    const onFinish = async (values: any) => {
        console.log("En desarrollo", values);
    }

    const findCountryByCode = (code: string) => {
        return countries.find((record) => {
            return record.code === code;
        });
    }

    const findApplicationProfileTypeById = (id: string) => {
        return applicationProfileTypes.find((record) => {
            return record.id === id;
        });
    }

    const isAssociatedToBusinessChain = () => {
        if(!currentUser) {
            return false;
        }

        const userTypeSelected = currentUser.roles.find((record) => {
            return record.applicationProfileTypeId === 'BUSINESS_CHAIN';
        });

        return !!userTypeSelected;
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={<span>Detalles del usuario - ID: {selectedUserId}{currentUser && currentUser.userType === 'SUPER_ADMIN' ? <Tag style={{ marginLeft: '10px' }} icon={<StarOutlined />} color="processing">Super usuario</Tag> : ''}</span>}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={640}
                loading={loading}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="País"
                            >
                                <Input prefix={<FlagOutlined />} placeholder="País" readOnly disabled={loading} type="text" value={findCountryByCode(currentUser?.company?.countryCode || '') ? findCountryByCode(currentUser?.company?.countryCode || '')?.name : ''}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Tipo de usuario"
                            >
                                <Input prefix={<TagsOutlined />} placeholder="Tipo de usuario" readOnly disabled={loading} type="text" value={findApplicationProfileTypeById((currentUser?.roles.length === 0 ? '' : currentUser?.roles[0].applicationProfileTypeId) || '') ? findApplicationProfileTypeById((currentUser?.roles.length === 0 ? '' : currentUser?.roles[0].applicationProfileTypeId) || '')?.title : ''}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Rol"
                            >
                                <Input prefix={<TeamOutlined />} placeholder="Rol" readOnly disabled={loading} type="text" value={currentUser ? currentUser.roles.map((record) => {
                                    return record.title
                                }).join(', ') : ''}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={isAssociatedToBusinessChain() ? 'Cadena' : 'Compañía'}
                            >
                                <Input prefix={<BankOutlined />} readOnly disabled={loading} type="text" value={currentUser?.company?.name}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="name"
                                label="Nombres"
                                rules={[{ required: true, message: 'Debes de ingresar los nombres.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los nombres"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="lastname"
                                label="Apellidos"
                                rules={[{ required: true, message: 'Debes de ingresar los apellidos.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los apellidos" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="email"
                                label="Correo"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: "Debes de ingresar un correo válido",
                                    }
                                ]}
                            >
                                <Input type="email" disabled={loading} placeholder="Ingrese el correo"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="phone"
                                label="Teléfono"
                                rules={[
                                    { required: true, message: 'El teléfono es requerido' },
                                    {
                                        validator: (_, value) => {
                                            if (value && value.valid()) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Debes ingresar un número de teléfono válido.'));
                                        },
                                    },
                                ]}
                            >
                                <PhoneInput enableSearch searchNotFound="No se encontró el país" searchPlaceholder="Buscar país" allowClear placeholder="Ingrese el teléfono" disabled={loading}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} disabled={true} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default UserDetailModal;
