import React, {useEffect, useState} from 'react';
import {RequestActivation} from "../../../../models/RequestActivation";
import {Button, Col, Divider, Form, message, Modal, Row, Select, UploadFile, UploadProps} from "antd";
import {InboxOutlined, UploadOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import {RequestActivationService} from "../../../../services/RequestActivationService";

interface UploadQuoteModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    selectedRequestActivation: RequestActivation;
    fetchParenData: (reloadGrid?: boolean) => void;
}

function UploadQuoteModal({ isOpen, handleModalCancel, selectedRequestActivation, fetchParenData }: UploadQuoteModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if(isOpen) {
            setLoading(false);
            form.resetFields();
            setFileList([]);
        }
    }, [isOpen]);

    const props: UploadProps = {
        onRemove: (file) => {
            if(!loading) {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
            }
        },
        multiple: false,
        beforeUpload: (file) => {
            if(!loading) {
                const isPDF = file.type === 'application/pdf';
                if (!isPDF) {
                    messageApi.error(`${file.name} no es un archivo PDF.`, 3.5);
                }else {
                    setFileList([file]);
                }
            }
            return false;
        },
        fileList
    };

    const onFinish = async (values: any) => {
        if(!loading) {
            setLoading(true);
            const response = await RequestActivationService.updateStatusToConfirmed(selectedRequestActivation.id, values.currency, values.final_quote.file);

            if(response.success) {
                await fetchParenData(true);
                messageApi.success(`Se subió la cotización final de manera satisfactoria.`, 3.5);
                handleModalCancel();
                setLoading(false);
            }else {
                const error = response.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar subir la cotización final, por favor inténtalo nuevamente.', 3.5);
                setLoading(false);
            }

        }
    }

    return (
        <>
            { contextHolder }

            <Modal
                title={<span><UploadOutlined /> Subir cotización final</span>}
                open={isOpen}
                onCancel={() => {
                    if(!loading) {
                        handleModalCancel();
                    }
                }}
                maskClosable={false}
                destroyOnClose
                footer={null}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Moneda"
                                name="currency"
                                rules={[
                                    { required: true, message: 'El campo es requerido' },
                                ]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    options={[
                                        { value: 'UF', label: 'UF' },
                                        { value: 'CLP', label: 'CLP' },
                                        { value: 'PEN', label: 'PEN' },
                                        { value: 'USD', label: 'USD' },
                                    ]}
                                    placeholder="Seleccionar moneda"
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Documento - Cotización final"
                                name="final_quote"
                                rules={[
                                    { required: true, message: 'Se debe de cargar un documento' },
                                ]}
                            >
                                <Dragger
                                    {...props}
                                    multiple={false}
                                    disabled={loading}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Haga click o arrastre el archivo a esta área para cargarlo</p>
                                    <p className="ant-upload-hint">
                                        Solo se cargará un archivo. Tenga en cuenta que el formato soportado por el sistema es PDF
                                    </p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Button
                                block
                                type="primary"
                                onClick={() => {
                                    form.submit();
                                }}
                                loading={loading}
                            >
                                Cargar cotización
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default UploadQuoteModal;
