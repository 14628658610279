import React from 'react';
import {Timeline, TimelineItemProps} from "antd";
import dayjs from "dayjs";
import {RequestActivation} from "../../../../models/RequestActivation";
import {RequestActivationLog} from "../../../../models/RequestActivationLog";
import {InfoCircleOutlined} from "@ant-design/icons";

interface AdminTrackTabProps {
    requestActivation: RequestActivation;
    logs: RequestActivationLog[];
}

function AdminTrackTab({ requestActivation, logs }: AdminTrackTabProps) {

    const getTimeLineItems = (): TimelineItemProps[] => {
        return logs
            .sort((a, b) => {
                return b.id - a.id;
            })
            .map((record) => {
                return {
                    color: record.requestActivationStatus?.privateColor || 'gray',
                    label: <span style={{ fontSize: '11px' }}>{dayjs(record.createdAt).format('DD/MM/YYYY hh:mm A')}</span>,
                    children: (
                        <>
                            <p style={{ textDecoration: 'underline' }}>{record.requestActivationStatus?.privateName}</p>
                            <p>Realizado por: <span style={{ color: '#707070', fontStyle: 'italic', cursor: 'pointer' }} title={record.user?.email}>{record.user?.name} {record.user?.lastname}</span></p>
                            {
                                 ['REFUSED', 'REFUSED_PURCHASE_ORDER', 'REFUSED_BY_BUSINESS_CHAIN'].includes(record.requestActivationStatusId) && (
                                    <p>Motivo de rechazo: <span style={{ color: '#707070', fontStyle: 'italic' }}>{ record.additionalData.content }</span></p>
                                )
                            }
                            {
                                ['CONFIRMED_BY_BUSINESS_CHAIN'].includes(record.requestActivationStatusId) && (
                                    <p><InfoCircleOutlined /> <span style={{ color: '#707070', fontStyle: 'italic' }}>Equipo procesando cotización final</span></p>
                                )
                            }

                            {
                                ['IN_VALIDATION'].includes(record.requestActivationStatusId) && (
                                    <p><InfoCircleOutlined /> <span style={{ color: '#707070', fontStyle: 'italic' }}>Solicitud en revisión por la cadena</span></p>
                                )
                            }
                        </>
                    )
                };
            });
    }


    return (
        <Timeline
            mode="alternate"
            items={getTimeLineItems()}
        />
    );
}

export default AdminTrackTab;
