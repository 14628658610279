import {theme, ThemeConfig} from "antd";

export const useThemeLayoutConfig = (): ThemeConfig => {
    const themeToken = theme.useToken();

    const customThemeConfig: ThemeConfig =  {
        token: {
            colorPrimary: '#1F3867',
            fontFamily: themeToken.token.fontFamily,
            lineHeight: themeToken.token.lineHeight,
            colorText: themeToken.token.colorText,
            // borderRadius: 12
        },
        components: {
            Menu: {
                itemSelectedBg: '#e5e9f1',
                itemActiveBg: '#e5e9f1',
                itemSelectedColor: '#1F3867',
            },
            Layout: {
                triggerBg: '#1F3867',
            },
            Button: {
                // borderRadius: 24,
            },
            Input: {
                // borderRadius: 12,
            },
            Select: {
                // borderRadius: 12,
            },
            DatePicker: {
                // borderRadius: 12,
            }
        },
    };

    return {
        ...customThemeConfig
    }
};
