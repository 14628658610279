import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Contact} from "../models/Contact";

export const ContactService = {
    getByCurrentCompany: async (): Promise<ApiResponse<Contact[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/contact/get-by-current-company`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as Contact[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};
