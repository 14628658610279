import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Store} from "../models/Store";

export const StoreService = {
    getByBusinessChain: async (businessChainId: number): Promise<ApiResponse<Store[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/store/get-by-business-chain/${businessChainId}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as Store[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAvailableBySelectedCompany: async (): Promise<ApiResponse<Store[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/store/get-available-by-selected-company`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as Store[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};
