import { Permission } from '../models/Permission';
import {User} from "../models/User";

export const hasRoutePermissions = (
  permissions: string | string[],
  currentUser: any
) => {
  if (currentUser.id === 0) {
    return false;
  }

  const currentPermissions: Permission[] = currentUser.permissions || [];

  if (typeof permissions === 'string') {
    permissions = [permissions];
  }

  if (permissions.length === 0) {
    return true;
  } else {
    let check = 0;

    for (let permission of permissions) {
      permission = permission.trim().toUpperCase();

      if (
        currentPermissions.find((currentPermission: Permission) => {
          return currentPermission.code === permission;
        })
      ) {
        check++;
      }
    }

    return check === permissions.length;
  }
};

export const getFormattedUserData = (userData: any): User => {
  return {
    id: userData.id,
    username: userData.username,
    userType: userData.userType,
    name: userData.name,
    lastname: userData.lastname,
    email: userData.email,
    dialCode: userData.dialCode,
    phone: userData.phone || null,
    avatar: userData.avatar || null,
    createdAt: userData.createdAt,
    activatedAt: userData.activatedAt || null,
    disabledAt: userData.disabledAt || null,
    token: userData.token,
    permissions: userData.permissions
        ? userData.permissions.map((permission: any) => {
          return {
            code: permission.code,
            name: permission.name,
            description: permission.description,
            createdAt: permission.createdAt,
          };
        })
        : [],
    roles: userData.roles
        ? userData.roles.map((role: any) => {
          return {
            id: role.id,
            name: role.name,
            title: role.title,
            description: role.description,
            createdAt: role.createdAt,
          };
        })
        : [],
    allowedMenus: userData.menus,
    company: {
      id: userData.company.id,
      name: userData.company.name,
      activationRequestPrefix: userData.company.activationRequestPrefix,
      taxRate: parseFloat(`${userData.company.taxRate}`),
      currencyCode: userData.company.currencyCode,
      currency: {
        code: userData.company.currency.code,
        name: userData.company.currency.name,
        symbol: userData.company.currency.symbol,
        createdAt: userData.company.currency.createdAt
      },
      businessChain: userData.company.businessChain,
      applicationProfileTypeId: userData.company.applicationProfileTypeId,
      logo: userData.company.currency.logo,
      createdAt: userData.company.createdAt,
      updatedAt: userData.company.userType,
      countryCode: userData.company.countryCode,
      description: userData.company.description
    }
  };
}
