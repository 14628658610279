import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Schedule} from "../models/Schedule";

export const ScheduleService = {
    getAllBySelectedCompanyAndRequestActivationType: async (requestActivationTypeId: number): Promise<ApiResponse<Schedule[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/available-schedule/get-all-by-selected-company-and-request-activation-type/${requestActivationTypeId}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as Schedule[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAllBySelectedCompany: async (): Promise<ApiResponse<any[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/available-schedule/get-all-by-selected-company`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as any[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};
