import React, {useEffect, useState} from 'react';
import {
    EyeOutlined,
    LockOutlined,
    MoreOutlined,
    PlusOutlined,
    TeamOutlined, UnlockOutlined
} from "@ant-design/icons";
import Layout from "../../components/Layout/Layout";
import {
    Button,
    Card,
    Col,
    Dropdown,
    MenuProps,
    message,
    Modal,
    Row, Select,
    Table,
    TableProps,
    Tag, Tooltip
} from "antd";
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/es/input";
import {User} from "../../models/User";
import {TableParams} from "../../models/TableParams";
import {PAGE_SIZE} from "../../config/Constants";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import {CreateRegularAccountRequestDto, UserService} from "../../services/UserService";
import {CountryService} from "../../services/CountryService";
import {RoleService} from "../../services/RoleService";
import {CompanyService} from "../../services/CompanyService";
import {Role} from "../../models/Role";
import {Company} from "../../models/Company";
import {Country} from "../../models/Country";
import FilterContainer, {FilterContainerItem} from "../../components/FilterContainer/FilterContainer";
import StatusIndicator from "../../components/StatusIndicator/StatusIndicator";
import NewUserModal from "./components/NewUserModal";
import UserDetailModal from "./components/UserDetailModal";
import {ApplicationProfileTypeService} from "../../services/ApplicationProfileTypeService";
import {ApplicationProfileType} from "../../models/ApplicationProfileType";

import './UserManagement.scss';

interface InitialFilterValues {
    userType?: number;
    status?: 0 | 1;
}

function UserManagement() {
    const [modal, contextModalHolder] = Modal.useModal();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<User[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: 'createdAt',
        sortOrder: 'descend'
    });

    /* Filters */
    const [term, setTerm] = useState('');
    const [additionalFilters, setAdditionalFilters] = useState<InitialFilterValues>({});

    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<User | undefined>(undefined);

    const [roles, setRoles] = useState<Role[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);
    const [applicationProfileTypes, setApplicationProfileTypes] = useState<ApplicationProfileType[]>([]);

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term, additionalFilters.status, additionalFilters.userType]);

    useEffect(() => {
        init();

    }, []);

    const init = async () => {
        const [
            countryResponse,
            roleResponse,
            companyResponse,
            applicationProfileTypeResponse
        ] = await Promise.all([
            CountryService.getAll(),
            RoleService.getAll(),
            CompanyService.getAll(),
            ApplicationProfileTypeService.getAll(),
            fetchData()
        ]);

        if(countryResponse.success && roleResponse.success && companyResponse.success && applicationProfileTypeResponse.success) {
            setCountries(countryResponse.data);
            setRoles(roleResponse.data);
            setCompanies(companyResponse.data);
            setApplicationProfileTypes(applicationProfileTypeResponse.data);
            setLoading(false);
        }else {
            if(!countryResponse.success) {
                const error = countryResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los países, por favor inténtalo nuevamente.', 3.5);
            }

            if(!roleResponse.success) {
                const error = roleResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los roles, por favor inténtalo nuevamente.', 3.5);
            }

            if(!companyResponse.success) {
                const error = companyResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener las compañías, por favor inténtalo nuevamente.', 3.5);
            }

            if(!applicationProfileTypeResponse.success) {
                const error = applicationProfileTypeResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los tipos de usuario, por favor inténtalo nuevamente.', 3.5);
            }
        }
    }

    const fetchData = async (currentPage?: number) => {
        setTableLoading(true);

        const termFilter = term.trim() || undefined;

        const rolesFilter = additionalFilters.userType ? (
            roles.filter((record) => {
                return additionalFilters.userType! === record.id
            }).map((record) => {
                return record.name
            })
        ) : undefined;

        const isDisabled = additionalFilters.status !== undefined ? (additionalFilters.status === 0) : undefined;


        const datasourceResponse = await UserService.getAllByFiltersAndPaginate({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            sortField: tableParams.sortField,
            sortOrder: tableParams.sortOrder,
            term: termFilter,
            roles: rolesFilter,
            isDisabled
        });

        if(datasourceResponse.success) {
            const data = datasourceResponse.data;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = datasourceResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const handleTableChange: TableProps<User>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const getDropdownMenu = (row: User): MenuProps['items'] => {
        const menus: MenuProps['items'] = [
            {
                key: '1',
                label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver detalle</span>,
                onClick: () => { showDetailModalOpen(row); }
            },
        ];

        if(row.disabledAt) {
            menus.push({
                key: '2',
                label: <span><UnlockOutlined style={{ marginRight: '8px' }}/> Activar usuario</span>,
                onClick: async () => { await setUserAsActive(row); }
            });
        }else {
            menus.push({
                key: '2',
                label: <span><LockOutlined style={{ marginRight: '8px' }}/> Desactivar usuario</span>,
                onClick: async () => { await setUserAsInactive(row); },
                danger: true
            });
        }

        return menus;
    }

    const setUserAsInactive = async (user: User) => {
        setTableLoading(true);
        const response = await UserService.setAsInactive(user.id);

        if(response.success) {
            await fetchData();
            messageApi.success(<span>Se desactivó al usuario con el correo <b>{user.email}</b> y con el ID <b>{user.id}</b> de manera satisfactoria.</span>)
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar desactivar al usuario, por favor inténtalo nuevamente.', 3.5);
        }
    }

    const setUserAsActive = async (user: User) => {
        setTableLoading(true);
        const response = await UserService.setAsActive(user.id);

        if(response.success) {
            await fetchData();
            messageApi.success(<span>Se activó al usuario con el correo <b>{user.email}</b> y con el ID <b>{user.id}</b> de manera satisfactoria.</span>)
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar activar al usuario, por favor inténtalo nuevamente.', 3.5);
        }
    }

    const showDetailModalOpen = (row: User) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const closeDetailModalOpen = () => {
        setIsDetailModalOpen(false);
    }

    const showNewUserModalOpen = () => {
        if(!tableLoading) {
            setIsNewUserModalOpen(true);
        }
    }

    const closeNewUserModalOpen = () => {
        setIsNewUserModalOpen(false);
    }

    const createUser = async (params: CreateRegularAccountRequestDto) => {
        setTableLoading(true);

        const newUserResponse = await UserService.createRegularAccount(params);

        if(newUserResponse.success) {
            await fetchData(1);
            closeNewUserModalOpen();
            messageApi.success(<span>Se creó el usuario con el correo <b>{params.email.toLowerCase()}</b> con un ID <b>{newUserResponse.data.createdId}</b> satisfactoriamente.</span>, 3.5);
        }else {
            const error = newUserResponse.data;
            let errorMessage = error.message as string || 'Hubo un error al intentar crear al usuario, por favor inténtalo nuevamente.';

            if(error.code === 'user.usernameAlreadyExists') {
                errorMessage = `El correo electrónico enviado ya está en uso, por favor ingrese otro.`;
            }

            messageApi.error(errorMessage, 3.5);
            setTableLoading(false);
        }
    }

    const filterOptions: FilterContainerItem[] = [
        {
            key: 'userType',
            label: 'Rol',
            isOpen: true,
            element: (
                <Select
                    options={roles.map((record) => {
                        return {
                            label: record.title,
                            value: record.id
                        };
                    })}
                    placeholder="-Todos-"
                    allowClear
                />
            )
        },
        {
            key: 'status',
            label: 'Estado',
            isOpen: true,
            element: (
                <Select
                    style={{ width: '100%' }}
                    options={[
                        {
                            label: <StatusIndicator text="Activo" color="#52C41A"/>,
                            value: 1
                        },
                        {
                            label: <StatusIndicator text="Inactivo" color="#FF4D4F"/>,
                            value: 0
                        },
                    ]}
                    placeholder="-Todos-"
                    allowClear
                />
            )
        }
    ];

    const additionalFilterSubmit = (values: any) => {
        setAdditionalFilters({
            status: values.status,
            userType: values.userType
        });
    }

    return (
        <Layout breadcrumb={[
            { title: <span><TeamOutlined /> Usuarios</span> }
        ]}
        >
            { contextModalHolder }
            { contextHolder }

            <Card
                loading={loading}
            >
                <div className="filter-container">
                    <Row gutter={[24, 16]}>
                        <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                            <label className="filter-search-container">Filtrar por: <Search placeholder="Nombre y apellido o correo" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                            <FilterContainer
                                loading={tableLoading}
                                roles={roles}
                                submit={additionalFilterSubmit}
                                items={filterOptions}
                                initialValues={additionalFilters}
                            />

                            <Button type="primary" onClick={showNewUserModalOpen}  disabled={tableLoading}><PlusOutlined /> Crear usuario</Button>
                        </Col>
                    </Row>
                </div>
            </Card>

            <Card
                style={{ marginTop: '18px' }}
                loading={loading}
            >
                <Table
                    <User>
                    dataSource={dataSource}
                    bordered
                    loading={tableLoading}
                    pagination={tableParams.pagination}
                    size="small"
                    scroll={{ y: `CALC(100VH - 390px)`, x: 768 }}
                    rowKey={(record) => { return record.id }}
                    onChange={handleTableChange}
                >
                    <Column width={80} fixed="left" align="center" title="ID" dataIndex="id" key="id"/>

                    <Column sorter={true} width={320} ellipsis title="Nombre y apellido" key="fullName" render={(row: User) => (
                        <>{row.name} {row.lastname}</>
                    )}/>

                    <Column sorter={true} ellipsis title="Correo" key="email" render={(row: User) => (
                        <>{row.email}</>
                    )}/>

                    <Column align="center" title="Fecha de creación" key="createdAt" render={(row: User) => (
                        <span title={dayjs(row.createdAt).format('DD/MM/YYYY hh:mm A')}>{dayjs(row.createdAt).format('DD/MM/YYYY')}</span>
                    )} sorter={true} defaultSortOrder="descend" width={150} hidden/>

                    <Column width={240} align="center" title="Rol" key="roles" render={(row: User) => (
                        <>
                            {
                                row.roles.length === 0 && (
                                    <Tag color="default">No presenta</Tag>
                                )
                            }
                            {
                                row.roles.length === 1 && (
                                    <Tag color={row.disabledAt ? 'error' : 'processing'}>{ row.roles[0].title }</Tag>
                                )
                            }

                            {
                                row.roles.length > 1 && (
                                    <Tooltip title={`${row.roles.map((record) => record.title).join(', ')}`}>
                                        <Tag style={{ cursor: 'pointer' }} color="purple">Más de 1 tipo</Tag>
                                    </Tooltip>
                                )
                            }
                        </>
                    )} />

                    <Column width={100} ellipsis title="Estado" key="status" render={(row: User) => (
                        <StatusIndicator text={!row.disabledAt ? 'Activo': 'Inactivo'} color={!row.disabledAt ? '#52C41A': '#FF4D4F'}/>
                    )} />

                    <Column width={60} fixed="right" align="center" title="" key="actions" render={(row) => (
                        <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                            <Button size="small"><MoreOutlined /></Button>
                        </Dropdown>
                    )} />
                </Table>
            </Card>

            {/* Modals */}
            <NewUserModal
                isOpen={isNewUserModalOpen}
                handleModalCancel={closeNewUserModalOpen}
                countries={countries}
                companies={companies}
                roles={roles}
                applicationProfileTypes={applicationProfileTypes}
                submit={createUser}
            />

            {
                selectedRow && (
                    <UserDetailModal
                        isOpen={isDetailModalOpen}
                        selectedUserId={selectedRow.id}
                        handleModalCancel={closeDetailModalOpen}
                        countries={countries}
                        applicationProfileTypes={applicationProfileTypes}
                    />
                )
            }
        </Layout>
    );
}

export default UserManagement;
