import React, {useEffect, useState} from 'react';
import {Contact} from "../../../../../models/Contact";
import {Card, Col, Divider, message, Modal, Row} from "antd";
import {RequestActivation} from "../../../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../../../config/ThemeDashboardLayoutConfig";
import RequestActivationDetailModalSkeleton from "./RequestActivationDetailModalSkeleton";
import LeftSidebar from "./LeftSidebar";
import {RequestActivationService} from "../../../../../services/RequestActivationService";
import {RequestActivationLog} from "../../../../../models/RequestActivationLog";
import {RequestActivationLogService} from "../../../../../services/RequestActivationLogService";
import GeneralDetailTab from "./GeneralDetailTab";
import InformationTab from "./InformationTab";
import NoteTab from "./NoteTab";

import './RequestActivationDetailModal.scss';
import TrackTab from "./TrackTab";
import {
    CreditCardOutlined,
    FieldTimeOutlined,
    FileOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";

interface RequestActivationDetailModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    selectedRequestActivation: RequestActivation;
    contacts: Contact[];
    reloadGrid: (currentPage?: number) => void;
}

function RequestActivationDetailModal({ selectedRequestActivation, isOpen, handleModalCancel, contacts, reloadGrid }: RequestActivationDetailModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const themeConfig = useThemeLayoutConfig();
    const staticHeight = '65vh';
    const [activeTabKey, setActiveTabKey] = useState<string>('general_details');
    const [requestActivation, setRequestActivation] = useState<RequestActivation>();
    const [logs, setLogs] = useState<RequestActivationLog[]>([]);

    useEffect(() => {
        setRequestActivation(undefined);

        if(isOpen) {
            setActiveTabKey('general_details');
            fetchData();
        }
    }, [isOpen])

    const fetchData = async (reloadGridValue: boolean = false) => {

        const [
            requestActivationResponse,
            requestActivationLogResponse
        ] = await Promise.all([
            RequestActivationService.findById(selectedRequestActivation.id),
            RequestActivationLogService.getByRequestActivation(selectedRequestActivation.id, { isRealStatus: true })
        ]);

        if(requestActivationResponse.success && requestActivationLogResponse.success) {
            setRequestActivation(requestActivationResponse.data);
            setLogs(requestActivationLogResponse.data);
            if(reloadGridValue) {
                reloadGrid();
            }
        }else {
            if(!requestActivationResponse.success) {
                const error = requestActivationResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar cancelar la solicitud de activación, por favor inténtalo nuevamente.', 3.5);
            }

            if(!requestActivationLogResponse.success) {
                const error = requestActivationLogResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar cancelar la solicitud de activación, por favor inténtalo nuevamente.', 3.5);
            }
        }
    }

    const tabList = [
        { key: 'general_details', label: 'Detalles generales', icon: <CreditCardOutlined /> },
        { key: 'request_information', label: 'Información de solicitud', icon: <InfoCircleOutlined /> },
        { key: 'notes', label: 'Notas', icon: <FileOutlined /> },
        { key: 'tracking', label: 'Seguimiento', icon: <FieldTimeOutlined /> },
    ];

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const tabsContent: Record<string, React.ReactNode> = {
        general_details: <GeneralDetailTab requestActivation={requestActivation!} fetchData={fetchData} />,
        request_information: <InformationTab requestActivation={requestActivation!} />,
        notes: <NoteTab requestActivation={requestActivation!} />,
        tracking: <TrackTab requestActivation={requestActivation!} logs={logs}/>
    };

    return (
        <>
            { contextHolder }

            <Modal
                title={<span>Solicitud N°: <span style={{ color: themeConfig.token?.colorPrimary }} >{selectedRequestActivation.id}</span></span>}
                open={isOpen}
                onCancel={handleModalCancel}
                okText="Guardar"
                maskClosable={false}
                destroyOnClose
                width="90%"
                footer={null}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                {
                    !requestActivation && (
                        <RequestActivationDetailModalSkeleton/>
                    )
                }

                {
                    requestActivation && (
                        <Row gutter={24}>
                            <Col xs={24} md={24} lg={6} xl={6}  xxl={6} style={{ marginBottom: '15px' }}>
                                <Card style={{ height: staticHeight, overflowX: "auto" }}>
                                    <LeftSidebar requestActivation={requestActivation} contacts={contacts} />
                                </Card>
                            </Col>

                            <Col xs={24} md={24} lg={18} xl={18} xxl={18} style={{ marginBottom: '15px' }}>
                                <Card
                                    style={{
                                        height: staticHeight,
                                        overflowX: "auto",
                                    }}
                                    tabList={tabList}
                                    tabProps={{
                                        size: 'middle',
                                    }}
                                    activeTabKey={activeTabKey}
                                    onTabChange={onTabChange}
                                    className={ activeTabKey === 'notes' ? 'request-activation-detail-modal-card-body-notes-padding' : '' }
                                >
                                    {requestActivation && tabsContent[activeTabKey]}
                                </Card>
                            </Col>
                        </Row>
                    )
                }
            </Modal>
        </>
    );
}

export default RequestActivationDetailModal;
