import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Checkbox,
    Col,
    ConfigProvider,
    Flex,
    Form, GetProp,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Typography
} from "antd";
import {useThemeLayoutConfig} from "../../config/ThemeDashboardLayoutConfig";
import {useDispatch} from "react-redux";
import Password from "antd/es/input/Password";
import {AuthService} from "../../services/AuthService";
import {User} from "../../models/User";
import AuthAction from "../../redux/actions/authActions/Actions";
import {ApiErrorData} from "../../models/ApiResponse";
import moment from "moment";

import './SignIn.scss';
import esES from "antd/locale/es_ES";
import {MailOutlined, UserOutlined} from "@ant-design/icons";
import {FunctionsHelper} from "../../utils/FunctionsHelper";
import {UserService} from "../../services/UserService";
import {OTPProps} from "antd/es/input/OTP";

function SignIn() {
    const themeConfig = useThemeLayoutConfig();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    /* Password recovery modal settings */
    const [passwordRecoveryLoading, setPasswordRecoveryLoading] = useState(false);
    const [isPasswordRecoveryModalOpen, setIsPasswordRecoveryModalOpen] = useState(false);
    const [emailInputRecoveryPassword, setEmailInputRecoveryPassword] = useState('');
    const [emailInputHaveError, setEmailInputHaveError] = useState(false);

    /* Verify user modal settings */
    const [isVerifyUserModalOpen, setIsVerifyUserModalOpen] = useState(false);
    const [noVerifyUserData, setNoVerifyUserData] = useState<{ id: number, username: string, password: string } | null>(null);

    /* OTP */
    const [otpLoading, setOtpLoading] = useState(false);
    const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
    const [expiredOtp, setExpiredOtp] = useState<Date>(new Date());
    const [progressOtp, setProgressOtp] = useState(0);
    const [otpCode, setOtpCode] = useState('');
    const [otpInputHaveError, setOtpInputHaveError] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = moment();
            const total = 280;
            const remaining = now.diff(moment(expiredOtp), 'seconds');

            if(remaining > 0) {
                const perc = 100 - Math.floor((100)*(total-remaining)/total);
                setProgressOtp(perc);
            }else {
                setProgressOtp(0);
            }

        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const onFinish = async (values: any) => {
        if(!loading) {
            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Comprobando credenciales...',
                duration: 0
            });
            setLoading(true);

            const loginResponse = await AuthService.login({ username: values.username, password: values.password });

            messageApi.destroy(loadingMessageKey);

            if(loginResponse.success) {
                messageApi.success('Autenticó sus credenciales con éxito.', 3.5);
                const currentUser = loginResponse.data as User;
                dispatch(AuthAction.signIn(currentUser));
            }else{
                const error = loginResponse.data as ApiErrorData;
                if(error.code === 'user.unverifiedUser') {
                    const payload: { userId: number, activationCodeExpiredAt: string | null } = error.payload as any;

                    if(payload.activationCodeExpiredAt) {
                        const expirationDate = moment(payload.activationCodeExpiredAt).add(-20, 'seconds').toDate();

                        if (expirationDate >= new Date()) {
                            setExpiredOtp(expirationDate);
                            setNoVerifyUserData({ id: payload.userId, username: values.username, password: values.password });
                            showOtpModal();
                        } else {
                            showVerifyUserModal(payload.userId, values.username, values.password);
                        }
                    }else {
                        showVerifyUserModal(payload.userId, values.username, values.password);
                    }

                }else {
                    messageApi.error(error.message as string || 'Hubo un error al intentar autenticar al usuario, por favor inténtalo nuevamente.', 3.5);
                }
            }

            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };

    const showPasswordRecoveryModal = () => {
        setEmailInputRecoveryPassword('');
        setEmailInputHaveError(false);
        setIsPasswordRecoveryModalOpen(true);
    };

    const handlePasswordRecoveryOk = async () => {


        if(!emailInputRecoveryPassword) {
            setEmailInputHaveError(true);
            messageApi.error('Debes de ingresar su cuenta de usuario.', 3.5);
            return;
        }

        if(!FunctionsHelper.validateEmail(emailInputRecoveryPassword)) {
            setEmailInputHaveError(true);
            messageApi.error('Debes de ingresar una cuenta de usuario válido (formato de correo electrónico).', 3.5);
            return;
        }

        setPasswordRecoveryLoading(true);

        const sendPasswordRecoveryResponse = await UserService.sendPasswordRecovery(emailInputRecoveryPassword);

        if(sendPasswordRecoveryResponse.success) {
            messageApi.success('Se envió el correo electrónico con el link para reiniciar su contraseña de manera satisfactoria.', 3.5);
            setIsPasswordRecoveryModalOpen(false);
        }else{
            const error = sendPasswordRecoveryResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar enviar el link para reinicio de su contraseña, por favor inténtalo nuevamente.', 3.5);
        }

        setPasswordRecoveryLoading(false);
    };

    const handlePasswordRecoveryCancel = () => {
        setIsPasswordRecoveryModalOpen(false);
    };

    const showVerifyUserModal = (selectedUserId: number, selectedUserName: string, selectedUserPassword: string) => {
        setNoVerifyUserData({ id: selectedUserId, username: selectedUserName, password: selectedUserPassword });
        setIsVerifyUserModalOpen(true);
    };

    const handleVerifyUserOk = async () => {
        if(noVerifyUserData?.id) {
            setIsVerifyUserModalOpen(false);
            setLoading(true);

            messageApi.open({
                type: 'loading',
                content: 'Enviando codigo de activación por correo electrónico..',
                duration: 0,
            });


            const sendActivationCodeResponse = await UserService.sendNewActivationCode(noVerifyUserData.id);

            messageApi.destroy();

            if(sendActivationCodeResponse.success) {
                const sendActivationCodeResponseData = sendActivationCodeResponse.data as { executedAction: boolean, userId: number, expiredCodeAt: Date };
                messageApi.success('Se envió el correo electrónico con el código de activación de manera satisfactoria.', 3.5);
                setExpiredOtp(moment(sendActivationCodeResponseData.expiredCodeAt).add(-20, 'seconds').toDate());
                showOtpModal();
            }else{
                const error = sendActivationCodeResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar enviar el código de activación, por favor inténtalo nuevamente.', 3.5);
            }

            setLoading(false);
        }
    }

    const handleVerifyUserCancel = () => {
        setIsVerifyUserModalOpen(false);
    };

    const showOtpModal = () => {
        setIsOtpModalOpen(true);
    }

    const handleOtpOk = async () => {
        if(otpCode && noVerifyUserData) {
            setOtpLoading(true);

            const verifyAccountResponse = await UserService.verifyUser(noVerifyUserData.id, otpCode);

            if(verifyAccountResponse.success) {
                const data = verifyAccountResponse.data as { executedAction: boolean, user: User };
                messageApi.success('Se activó la cuenta satisfactoriamente.', 3.5);
                setIsOtpModalOpen(false);
                const currentUser = data.user as User;
                dispatch(AuthAction.signIn(currentUser));
            }else{
                setOtpInputHaveError(true);
                const error = verifyAccountResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar activar la cuenta, por favor inténtalo nuevamente.', 3.5);
                setOtpLoading(false);
            }
        }
    }

    const handleOtpCancel = () => {
        setIsOtpModalOpen(false);
    }

    const onChange: GetProp<typeof Input.OTP, 'onChange'> = (text) => {
        console.log('onChange:', text);
    };

    const sharedProps: OTPProps = {
        onChange,
    };

    const resendVerifyCode = async () => {
        if(noVerifyUserData?.id) {
            setOtpLoading(true);

            messageApi.open({
                type: 'loading',
                content: 'Enviando codigo de activación por correo electrónico..',
                duration: 0,
            });


            const sendActivationCodeResponse = await UserService.sendNewActivationCode(noVerifyUserData.id);

            messageApi.destroy();

            if(sendActivationCodeResponse.success) {
                const sendActivationCodeResponseData = sendActivationCodeResponse.data as { executedAction: boolean, userId: number, expiredCodeAt: Date };
                messageApi.success('Se envió el correo electrónico con el código de activación de manera satisfactoria.', 3.5);
                setOtpCode('');
                setExpiredOtp(moment(sendActivationCodeResponseData.expiredCodeAt).add(-20, 'seconds').toDate());
            }else{
                const error = sendActivationCodeResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar enviar el código de activación, por favor inténtalo nuevamente.', 3.5);
            }
            setOtpLoading(false);
        }
    }

    return (
        <ConfigProvider locale={esES} theme={themeConfig}>

            {contextHolder}

            <main className="sign-in-main-container">
                <div className="sign-in-main-container-children">
                    <Form
                        name="sign-in-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        className="sign-in-form-container"
                    >
                        <div className="sign-in-main-logo-container">
                            <img className="sign-in-logo-img" src="/logofull.png" alt="logo"/>
                        </div>

                        <Typography className="sign-in-main-title" style={{ color: themeConfig.token?.colorPrimary }}>
                            Ingrese su nombre de usuario y contraseña para ingresar a nuestro sistema.
                        </Typography>

                        <Form.Item
                            name="username"
                            rules={[
                                { required: true, message: 'El correo electrónico es requerido' },
                            ]}
                        >
                            <Input
                                placeholder="Usuario"
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: 'La contraseña es requerida' },
                                { min: 4, message: 'La contraseña debe de tener un mínimo de 4 caracteres' },
                            ]}
                        >
                            <Password
                                placeholder="Contraseña"
                            />
                        </Form.Item>

                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>Recuérdame</Checkbox>
                        </Form.Item>

                        <Button type="primary" htmlType="submit" loading={loading} block>
                            INICIAR SESIÓN
                        </Button>

                        <p  style={{ color: themeConfig.token?.colorPrimary }} className="sign-in-forgotten-password" onClick={() => { showPasswordRecoveryModal() }}>Olvidé mi contraseña</p>
                    </Form>
                </div>
            </main>

            {/* Password recovery modal */}
            <Modal
                title="Olvidé mi contraseña"
                centered
                open={isPasswordRecoveryModalOpen}
                onOk={handlePasswordRecoveryOk}
                onCancel={handlePasswordRecoveryCancel}
                footer={[
                    <Button key="submit" type="primary" loading={passwordRecoveryLoading} onClick={handlePasswordRecoveryOk} icon={<MailOutlined/>} >
                        Enviar
                    </Button>,
                ]}
            >
                <p>Digite su cuenta de usuario al cual se enviará el link para el reinicio de su contraseña vía correo electrónico.</p>
                <Input type="text" style={{ marginTop: '15px' }} status={emailInputHaveError ? 'error': undefined} prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="Ingrese su cuenta de usuario" value={emailInputRecoveryPassword} onChange={(newValue) => {
                    setEmailInputRecoveryPassword(newValue.target.value);
                }} onFocus={() => {
                    setEmailInputHaveError(false);
                }}/>
            </Modal>

            {/* Verify user modal */}
            <Modal
                title="Verificar usuario"
                centered
                open={isVerifyUserModalOpen}
                onOk={handleVerifyUserOk}
                onCancel={handleVerifyUserCancel}
                footer={[
                    <Button key="submit" type="primary" loading={passwordRecoveryLoading} onClick={handleVerifyUserOk} icon={<MailOutlined/>}>
                        Enviar
                    </Button>,
                ]}
            >
                <p> Su cuenta de usuario no está activa, para activarla se enviará un código a su correo electrónico al dar clic en <b>enviar</b>.</p>
            </Modal>

            {/* OTP modal */}
            <Modal
                title="Código de verificación"
                centered
                open={isOtpModalOpen}
                onOk={handleOtpOk}
                onCancel={handleOtpCancel}
                footer={null}
            >
                <p style={{ marginTop: '10px' }}>
                    Ingresa el código de 4 dígitos que fue envíados a tu correo electrónico
                </p>
                <Flex gap="large" align="center" vertical style={{ marginTop: '30px' }}>
                    <Input.OTP
                        autoFocus
                        status={otpInputHaveError ? 'error' : undefined}
                        disabled={progressOtp === 0 || otpLoading}
                        formatter={(str) => str.toUpperCase()} {...sharedProps}
                        length={4}
                        value={progressOtp <= 0 ? '' : otpCode}
                        onChange={(newValue) => {
                            setOtpCode(newValue);
                        }}
                        onFocus={() => {
                            setOtpInputHaveError(false);
                        }}
                        onKeyDown={(event) => {
                            if(event.key === 'Enter') {
                                handleOtpOk();
                            }
                        }}
                    />

                    <Button key="submit" type="primary" loading={otpLoading} onClick={handleOtpOk} disabled={otpCode.length !== 4}>
                        Verificar usuario
                    </Button>

                    <Popconfirm
                        title="Confirmación"
                        description="¿Estás seguro que deseas reenviar el código de activación?"
                        okText="Confirmar"
                        cancelText="No"
                        onConfirm={() => { resendVerifyCode(); }}
                    >
                        <p style={{ color: themeConfig.token?.colorPrimary }} className="login-form-forgotten-password">¿No te llegó el código?, clic aquí para reenviarlo</p>
                    </Popconfirm>
                </Flex>
            </Modal>
        </ConfigProvider>
    );
}

export default SignIn;
