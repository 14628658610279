import React, {useEffect, useState} from 'react';
import {Country} from "../../../../models/Country";
import {
    Button,
    Col,
    Divider,
    Form, GetProp,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select, Upload, UploadProps
} from "antd";
import {ItemInterface} from "../../../../models/ItemInterface";
import TextArea from "antd/es/input/TextArea";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {CompanyService} from "../../../../services/CompanyService";
import {Company} from "../../../../models/Company";
import {FileService} from "../../../../services/FileService";

interface NewClientModalProps {
    id: number;
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (id: number, name: string, currencyCode: string, taxes: number, description: string | null, hasLogoChanged: boolean, logo?: File | null) => void;
    countries: Country[];
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

function DetailClientModal({ id, isOpen, handleModalCancel, submit, countries }: NewClientModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [company, setCompany] = useState<Company>();

    const [countryCodeSelected, setCountryCodeSelected] = useState<string>();

    const [imageUrl, setImageUrl] = useState<string>();
    const [imageFile, setImageFile] = useState<FileType | null>(null);
    const [hasLogoChanged, setHasLogoChanged] = useState(false);

    useEffect(() => {
        if(isOpen) {
            fetchData();
        }else {
            form.resetFields();
            setLoading(false);
            setCountryCodeSelected(undefined);
            setImageUrl(undefined);
            setImageFile(null);
            setCompany(undefined);
            setHasLogoChanged(false);
        }
    }, [isOpen]);

    const fetchData = async () => {
        setLoading(true);

        const response = await CompanyService.findById(id);

        if(response.success) {
            setHasLogoChanged(false);

            const localCompany = response.data;
            if(localCompany.logo) {
                const fileResponse = await FileService.getFileAsBase64(localCompany.logo)

                if(fileResponse.success) {
                    setImageUrl(fileResponse.data.base64);
                }
            }

            setCompany(localCompany);
            setCountryCodeSelected(localCompany.countryCode);

            form.setFieldValue('name', localCompany.name);
            form.setFieldValue('prefix', localCompany.activationRequestPrefix);
            form.setFieldValue('taxes', (Math.round(localCompany.taxRate * 100)));
            form.setFieldValue('description', localCompany.description || '');

            setLoading(false);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la compañía, por favor inténtalo nuevamente.', 3.5);
            handleModalCancel();
        }
    }

    const getCountries = (): ItemInterface[] => {
        return countries
            .filter((record) => {
                return record.isActive;
            })
            .map((record) => {
                return {
                    value: record.code,
                    label: record.name
                }
            });
    }

    const onFinish = async (values: any) => {

        setLoading(true);

        await submit(
            id,
            values.name!.trim(),
            company!.currencyCode,
            (((values.taxes * 100) / 100) / 100),
            values.description ? (values.description.trim() || null) : null,
            hasLogoChanged,
            imageFile
        );

        setLoading(false);
    }

    const getBase64 = (img: FileType, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Solo puedes cargar archivos tipo JPG o PNG');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('La imagen no puede superar los 2MB.');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = (info) => {
        const file = info.file.originFileObj as FileType;

        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'removed') {
            setLoading(false);
        }

        if (file && beforeUpload(file)) {
            setImageFile(file);
            getBase64(file, (url) => {
                setImageUrl(url);
                setHasLogoChanged(true);
                setLoading(false);
            });
        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{ loading ? 'Cargando...' : 'Cargar logo'}</div>
        </button>
    );

    return (
        <>
            {contextHolder}

            <Modal
                title={`Ver cliente [ID: ${id}]`}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={640}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24} style={{ marginBottom: '15px' }}>
                        <Col xs={24} lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombre del cliente"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el nombre"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="País"
                            >
                                <Select
                                    options={getCountries()}
                                    placeholder="-Seleccione-"
                                    disabled={true}
                                    value={countryCodeSelected}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="prefix"
                                label="Prefijo"
                                tooltip={'Texto que será usado para dar el formato de los números de solicitud. Ej. Prefijo: WNG, Solicitud: WNG00121'}
                                rules={[{ required: true, message: 'Debes de ingresar un prefijo' }]}
                            >
                                <Input type="text" disabled={true} placeholder="Ingrese un prefijo" maxLength={5}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="taxes"
                                label="IVA"
                                rules={[
                                    { required: true, message: 'Debes de ingresar un valor para el IVA' },
                                    {
                                        validator: (_, value) =>
                                            value > 0
                                                ? Promise.resolve()
                                                : Promise.reject(new Error('El valor debe ser mayor a 0'))
                                    }
                                ]}
                            >
                                <InputNumber<number>
                                    style={{ width: '100%' }}
                                    defaultValue={0}
                                    max={100}
                                    step={0.01}
                                    addonAfter={'%'}
                                    placeholder="Ingrese el valor del impuesto"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="description"
                                label="Descripción"
                            >
                                <TextArea rows={4} placeholder="Ingrese una descripción"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default DetailClientModal;
