import {ThemeConfig} from "antd";

export const CustomCssConfig  = {
    loadInitialCss: (themeConfig: ThemeConfig) => {
        const style = document.createElement('style');
        style.innerHTML = `
      .main-layout-menu .ant-menu-item-selected {
        border-radius: 0 !important;
        border-right: 4px solid ${themeConfig.token?.colorPrimary};
      }
      .main-layout-menu .ant-menu-item-active {
        border-radius: 0 !important;
      }
      .main-layout-menu .ant-menu-submenu-active .ant-menu-submenu-title{
        border-radius: 0 !important;
      }
    `;
        document.head.appendChild(style);
    }
}
