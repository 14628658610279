import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    DeleteOutlined, ExclamationCircleOutlined,
    EyeOutlined,
    FilterOutlined,
    FormOutlined,
    MoreOutlined
} from "@ant-design/icons";
import {Button, Card, Col, Dropdown, Input, MenuProps, message, Row, Table, TableProps, Tag} from "antd";
import {SearchProps} from "antd/es/input";
import {RequestActivation} from "../../../models/RequestActivation";
import Column from "antd/es/table/Column";
import {TableParams} from "../../../models/TableParams";
import {PAGE_SIZE} from "../../../config/Constants";
import {RequestActivationService} from "../../../services/RequestActivationService";
import {PaginationData} from "../../../models/PaginationData";
import moment from "moment/moment";
import {RequestActivationStatus} from "../../../models/RequestActivationStatus";
import RequestActivationDetailModal from "./components/RequestActivationDetailModal/RequestActivationDetailModal";
import {Contact} from "../../../models/Contact";
import {ContactService} from "../../../services/ContactService";
import CancelledStatusModal from "./components/CancelledStatusModal";
import {useSearchParams} from "react-router-dom";
import StatusIndicator from "../../../components/StatusIndicator/StatusIndicator";

import './ActivationHistory.scss';

const { Search } = Input;

function ActivationHistory() {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<RequestActivation[]>([]);
    const [requestActivationStatuses, setRequestActivationStatuses] = useState<RequestActivationStatus[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: 'createdAt',
        sortOrder: 'descend'
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const rqParam = searchParams.get('rq');
    const [term, setTerm] = useState(rqParam ? rqParam : '');
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<RequestActivation | undefined>(undefined);
    const [contacts, setContacts] = useState<Contact[]>([]);

    const [isCancelledStatusModalOpen, setIsCancelledStatusModalOpen] = useState(false);

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        searchParams.delete('rq');
        setSearchParams(searchParams);

        const [
            requestActivationStatusResponse,
            contactResponse
        ] = await Promise.all([
            RequestActivationService.getAllRequestActivationStatuses(),
            ContactService.getByCurrentCompany(),
            fetchData()
        ]);

        if(requestActivationStatusResponse.success && contactResponse.success) {
            setRequestActivationStatuses(requestActivationStatusResponse.data);
            setContacts(contactResponse.data);

            if(rqParam) {
                const row = await RequestActivationService.findById(rqParam);

                if(row.success) {
                    showDetailModalOpen(row.data);
                }else {
                    console.error(`Error rqParam: ${rqParam}`, row.data);
                }
            }

            setLoading(false);
        }else {
            if(!contactResponse.success) {
                const error = contactResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los contactos, por favor inténtalo nuevamente.', 3.5);
            }

            if(!requestActivationStatusResponse.success) {
                const error = requestActivationStatusResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los estados de las activaciones, por favor inténtalo nuevamente.', 3.5);
            }
        }
    }

    const fetchData = async (currentPage?: number) => {
        setTableLoading(true);


        const datasourceResponse = await RequestActivationService.getAllByCurrentUserAndSelectedCompany({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            sortField: tableParams.sortField,
            sortOrder: tableParams.sortOrder,
            term: term || undefined
        });

        if(datasourceResponse.success) {
            const data = datasourceResponse.data as PaginationData<RequestActivation>;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = datasourceResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const handleTableChange: TableProps<RequestActivation>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const getDropdownMenu = (row: RequestActivation): MenuProps['items'] => {
        let menu: MenuProps['items'] = [
            {
                key: '1',
                label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver solicitud</span>,
                onClick: () => { showDetailModalOpen(row); }
            }
        ];


        if(['CREATED', 'IN_VALIDATION', 'CONFIRMED_BY_BUSINESS_CHAIN', 'CONFIRMED', 'PURCHASE_ORDER_TO_REVIEW', 'REFUSED_PURCHASE_ORDER'].includes(row.requestActivationStatusId)) {
            menu.push({
                key: '2',
                label: <span><DeleteOutlined style={{ marginRight: '8px' }}/> Cancelar solicitud</span>,
                onClick: () => { showCancelledStatusModalOpen(row); }
            });
        }

        return menu;
    }

    const showDetailModalOpen = (row: RequestActivation) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const closeDetailModalOpen = () => {
        setIsDetailModalOpen(false);
    }

    const showCancelledStatusModalOpen = (row: RequestActivation) => {
        setSelectedRow(row);
        setIsCancelledStatusModalOpen(true);
    }

    const closeCancelledStatusModalOpen = () => {
        setIsCancelledStatusModalOpen(false);
    }

    const submitCancelledStatus = async () => {
        closeCancelledStatusModalOpen();
        await fetchData();
    }

    return (
        <>
            { contextHolder }

            <Layout breadcrumb={[
                { title: <span><FormOutlined/> Activaciones</span> },
                { title: 'Historial' }
            ]}
            >
                <Card
                    loading={loading}
                >
                    <div className="filter-container">
                        <Row gutter={[24, 16]}>
                            <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                                <label className="filter-search-container">Filtrar por: <Search defaultValue={term || ''} placeholder="N° de solicitud" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                                <Button type="primary" disabled={tableLoading}><FilterOutlined /></Button>
                            </Col>
                        </Row>
                    </div>
                </Card>

                <Card
                    style={{ marginTop: '18px' }}
                    loading={loading}
                >
                    <Table
                        <RequestActivation>
                        dataSource={dataSource}
                        bordered
                        loading={tableLoading}
                        pagination={tableParams.pagination}
                        size="small"
                        rowKey={(record) => { return record.id }}
                        onChange={handleTableChange}
                        scroll={{ y: `CALC(100VH - 390px)`, x: 768 }}
                    >
                        <Column width={120} fixed="left" align="center" title="N° Solicitud" dataIndex="id" key="id"/>

                        <Column width={240} ellipsis title="Marca" key="brand" render={(row: RequestActivation) => (
                            <>{row.brand?.name}</>
                        )} sorter={true}/>

                        <Column width={240} ellipsis title="Cadena" key="businessChainName" render={(row: RequestActivation) => (
                            <>{row.businessChainName}</>
                        )}/>

                        <Column align="center" width={180} title="Fecha de solicitud" key="createdAt" render={(row: RequestActivation) => (
                            <span title={moment(row.createdAt).format('DD/MM/YYYY hh:mm A')}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm A')}</span>
                        )} sorter={true} defaultSortOrder="descend"/>

                        <Column width={240} ellipsis title="Estado" key="status" render={(row: RequestActivation) => (
                            <StatusIndicator color={row.requestActivationStatus!.color} text={row.requestActivationStatus!.publicName}/>
                        )} />

                        <Column width={160} align="center" title="Tarea pendiente" key="attentionRequired" render={(row: RequestActivation) => (
                            <>
                                {
                                    ['CONFIRMED', 'REFUSED_PURCHASE_ORDER'].includes(row.requestActivationStatusId) && (
                                        <Tag icon={<ExclamationCircleOutlined />} color="processing">
                                            Cargar orden de compra
                                        </Tag>
                                    )
                                }
                                {
                                    !['CONFIRMED', 'PURCHASE_ORDER_TO_REVIEW'].includes(row.requestActivationStatusId) && (
                                        <Tag color="default">
                                            Ninguna
                                        </Tag>
                                    )
                                }
                            </>
                        )} />

                        <Column width={60} fixed="right" align="center" title="" key="actions" render={(row) => (
                            <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                                <Button size="small"><MoreOutlined /></Button>
                            </Dropdown>
                        )} />
                    </Table>
                </Card>

                {/* Modals */}
                {
                    selectedRow && (
                        <RequestActivationDetailModal
                            isOpen={isDetailModalOpen}
                            handleModalCancel={closeDetailModalOpen}
                            selectedRequestActivation={selectedRow}
                            contacts={contacts}
                            reloadGrid={fetchData}
                        />
                    )
                }

                {
                    selectedRow && (
                        <CancelledStatusModal
                            isOpen={isCancelledStatusModalOpen}
                            handleModalCancel={closeCancelledStatusModalOpen}
                            selectedRequestActivation={selectedRow}
                            submit={submitCancelledStatus}
                        />
                    )
                }
            </Layout>
        </>
    );
}

export default ActivationHistory;
