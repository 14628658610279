import React, {useEffect, useState} from 'react';
import {Contact} from "../../../../../models/Contact";
import {Card, Col, Divider, message, Modal, Row} from "antd";
import {RequestActivation} from "../../../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../../../config/ThemeDashboardLayoutConfig";
import BcRequestActivationDetailModalSkeleton from "./BcRequestActivationDetailModalSkeleton";
import BcLeftSidebar from "./BcLeftSidebar";
import {RequestActivationService} from "../../../../../services/RequestActivationService";
import BcGeneralDetailTab from "./BcGeneralDetailTab";
import BcScheduleTab from "./BcScheduleTab";
import BcNoteTab from "./BcNoteTab";
import {RequestActivationStatus} from "../../../../../models/RequestActivationStatus";

import './BcRequestActivationDetailModal.scss';
import {CalendarOutlined, CreditCardOutlined, FileOutlined} from "@ant-design/icons";

interface RequestActivationDetailModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    selectedRequestActivation: RequestActivation;
    contacts: Contact[];
    requestActivationStatuses: RequestActivationStatus[];
    showRefusedByBusinessChainStatusModalOpen: (row: RequestActivation, callback?: () => void) => void;
}

function BcRequestActivationDetailModal({ selectedRequestActivation, isOpen, handleModalCancel, contacts, requestActivationStatuses, showRefusedByBusinessChainStatusModalOpen }: RequestActivationDetailModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const themeConfig = useThemeLayoutConfig();
    const staticHeight = '65vh';
    const [activeTabKey, setActiveTabKey] = useState<string>('general_details');
    const [requestActivation, setRequestActivation] = useState<RequestActivation>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setRequestActivation(undefined);

        if(isOpen) {
            setActiveTabKey('general_details');
            fetchData();
        }
    }, [isOpen])

    const fetchData = async () => {
        setLoading(true);
        const [
            requestActivationResponse
        ] = await Promise.all([
            RequestActivationService.findById(selectedRequestActivation.id),
        ]);

        if(requestActivationResponse.success) {
            setRequestActivation(requestActivationResponse.data);
        }else {
            if(!requestActivationResponse.success) {
                const error = requestActivationResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar cancelar la solicitud de activación, por favor inténtalo nuevamente.', 3.5);
            }
        }
        setLoading(false);
    }

    const tabList = [
        { key: 'general_details', label: 'Detalles generales', icon: <CreditCardOutlined /> },
        { key: 'schedule', label: 'Programación', icon: <CalendarOutlined /> },
        { key: 'notes', label: 'Notas de solicitud', icon: <FileOutlined /> },
    ];

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const tabsContent: Record<string, React.ReactNode> = {
        general_details: <BcGeneralDetailTab requestActivation={requestActivation!} />,
        schedule: <BcScheduleTab requestActivation={requestActivation!} />,
        notes: <BcNoteTab requestActivation={requestActivation!} />,
    };

    const showRefusedByBusinessChainStatusModalOpenWrapper = () => {
        showRefusedByBusinessChainStatusModalOpen(selectedRequestActivation, fetchData);
    }

    return (
        <>
            { contextHolder }

            <Modal
                title={<span>Solicitud N°: <span style={{ color: themeConfig.token?.colorPrimary }} >{selectedRequestActivation.id}</span></span>}
                open={isOpen}
                onCancel={handleModalCancel}
                okText="Guardar"
                maskClosable={false}
                destroyOnClose
                width="90%"
                footer={null}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                {
                    (!requestActivation || loading) && (
                        <BcRequestActivationDetailModalSkeleton/>
                    )
                }

                {
                    !(!requestActivation || loading) && (
                        <Row gutter={24}>
                            <Col xs={24} md={24} lg={6} xl={6}  xxl={6} style={{ marginBottom: '15px' }}>
                                <Card style={{ height: staticHeight, overflowX: "auto" }}>
                                    <BcLeftSidebar
                                        requestActivation={requestActivation}
                                        contacts={contacts}
                                        requestActivationStatuses={requestActivationStatuses}
                                        fetchData={fetchData}
                                        showRefusedByBusinessChainStatusModalOpen={showRefusedByBusinessChainStatusModalOpenWrapper}
                                    />
                                </Card>
                            </Col>

                            <Col xs={24} md={24} lg={18} xl={18} xxl={18} style={{ marginBottom: '15px' }}>
                                <Card
                                    style={{
                                        height: staticHeight,
                                        overflowX: "auto",
                                    }}
                                    tabList={tabList}
                                    tabProps={{
                                        size: 'middle',
                                    }}
                                    activeTabKey={activeTabKey}
                                    onTabChange={onTabChange}
                                    className={ activeTabKey === 'notes' ? 'bc-request-activation-detail-modal-card-body-notes-padding' : '' }
                                >
                                    {requestActivation && tabsContent[activeTabKey]}
                                </Card>
                            </Col>
                        </Row>
                    )
                }
            </Modal>
        </>
    );
}

export default BcRequestActivationDetailModal;
