import { MAIN_BACKEND_URL } from "../config/Config";
import {ApiErrorData, ApiResponse, ApiResponseError} from "../models/ApiResponse";
import axios, { AxiosError } from "axios";
import {Menu} from "../models/Menu";
import {ApiErrorFormat} from "../models/ApiErrorFormat";
import {ThemeConfig} from "antd";
import {Dayjs} from "dayjs";

export const FunctionsHelper = {
  getS3FileUrl: (s3RawKey: string) => {
    return `${MAIN_BACKEND_URL}/file/download/${encodeURIComponent(s3RawKey)}`;
  },
  showS3FileUrl: (s3RawKey: string) => {
    return `${MAIN_BACKEND_URL}/file/show/${encodeURIComponent(s3RawKey)}`;
  },
  getErrorFormat: (error: any): ApiResponseError => {
    let status = 500;
    let errorCode = "GENERIC_ERROR";
    let errorMessage = "Error de red, no se pudo conectar al servidor.";
    let payload = undefined;
    let delimiter = undefined;

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const dataError = axiosError?.response?.data as ApiErrorFormat;

        if (dataError) {

          if(dataError.code && dataError.message) {
            errorCode = dataError.code;
            errorMessage = dataError.message;
            payload = dataError.payload || payload;
            delimiter = dataError.delimiter;
          }else {
            errorCode = "API_BAD_FORMAT_ERROR";
            errorMessage =
                "Error, obtuvo una respuesta sin el formato correcto desde servidor.";
          }
        } else {
          errorCode = "API_BAD_EMPTY_CONTENT_RESPONSE";
          errorMessage =
            "Error, obtuvo una respuesta vacía desde servidor.";
        }

        // Error de respuesta del servidor (4xx o 5xx)
        console.log(
          `Error: ${axiosError.response.status} - ${axiosError.response.statusText}`,
          dataError
        );
      } else if (axiosError.request) {
        // Error de solicitud (sin respuesta del servidor)
        errorCode = "SERVER_NO_RESPONSE_ERROR";
        errorMessage =
          "Error de solicitud, no se recibió respuesta del servidor.";
      } else {
        // Otros errores
        errorCode = "APPLICATION_PROCESS_ERROR";
        errorMessage = "Error al procesar la solicitud.";
      }
    }

    return {
      success: false,
      status,
      data: {
        code: errorCode,
        message: errorMessage,
        payload,
        delimiter
      },
    };
  },
  flattenMenuArray: (menuArray: Menu[]) => {
    let flattenedArray: any[] = [];
    menuArray.forEach((menu: any) => {
      flattenedArray.push(menu);
      if (menu.menus && menu.menus.length > 0) {
        flattenedArray = flattenedArray.concat(FunctionsHelper.flattenMenuArray(menu.menus));
        // delete menu.menus;
      }
    });
    return flattenedArray;
},
  flattenMenuArrayDashboardMenu: (menuArray: Menu[]) => {
    let flattenedArray: any[] = [];
    menuArray.forEach((menu: any) => {
      flattenedArray.push(menu);
      if (menu.children && menu.children.length > 0) {
        flattenedArray = flattenedArray.concat(FunctionsHelper.flattenMenuArrayDashboardMenu(menu.children));
        // delete menu.children;
      }
    });
    return flattenedArray;
  },
  validateEmail: (email: string):boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  },
  sleep: async (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  },
  getMonthName: (monthNumber: number) => {
    const months: string[] = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
    } else {
      return "Mes no válido";
    }
  },
  capitalizeFirstLetter: (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  simulateStream: (text: string) => {
    const chunks = text.match(/.{1,25}/g) || []; // Divide el texto en partes de 25 caracteres
    const encoder = new TextEncoder();

    return new ReadableStream({
      start(controller) {
        let index = 0;

        function push() {
          if (index < chunks.length) {
            controller.enqueue(encoder.encode(chunks[index]));
            index++;
            setTimeout(push, 100); // Ajusta el tiempo de retraso según sea necesario
          } else {
            controller.close();
          }
        }

        push();
      }
    });
  },
  getAvatarUi: (themeConfig: ThemeConfig, name: string, lastname: string) => {
    return `https://ui-avatars.com/api/?font-size=0.33&background=${themeConfig.components?.Menu?.itemSelectedBg?.replace('#', '')}&color=${themeConfig.token?.colorPrimary?.replace('#', '')}&name=${name.slice(0, 1)}+${lastname.slice(0, 1)}`;
  },
  normalizeText: (text: string | undefined) => {
    return (text || '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
  },
  generateUniqueId: () => {
    const timestamp = Date.now().toString(36);
    const randomNum = Math.random().toString(36).substring(2, 5);
    return (timestamp + randomNum);
  },
  openFileInOtherTab: (blob: Blob | MediaSource) => {
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL, '_blank');
    URL.revokeObjectURL(blobURL);
  },
  isSameOrAfter: (date1: Dayjs, date2: Dayjs): boolean => {
    return date1.isSame(date2, 'day') || date1.isAfter(date2, 'day');
  }
};
