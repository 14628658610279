import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {User} from "../../models/User";
import {MenuType} from "../../config/Config";
import {FunctionsHelper} from "../../utils/FunctionsHelper";
import Layout from "../../components/Layout/Layout";
import {ItemType} from "antd/es/breadcrumb/Breadcrumb";
import DynamicIcon from "../../components/DynamicIcon/DynamicIcon";
import {Card} from "antd";

import './DynamicViewer.scss';

function DynamicViewer() {
    const { key }: any = useParams();
    const user: User = useSelector((state: any) => state.auth);
    const [src, setSrc] = useState("");
    const [title, setTitle] = useState("");
    const [breadcrumb, setBreadcrumb] = useState<ItemType[]>([]);

    /* Settings */
    const heightOffset = 164 + 36;
    const heightPadding = 48;
    const vh = window.innerHeight;
    const vw = window.innerWidth;

    useEffect(() => {
        const localBreadcrumb: ItemType[] = [];
        const availableData = FunctionsHelper.flattenMenuArray(user.allowedMenus).find((menu) => {
            return menu.code === key && menu.menuTypeId === MenuType.IFRAME_VIEWER;
        });

        if (availableData) {
            const url = availableData.redirectLink || "";
            const prefixUrl = availableData?.additionalConfig?.prefixUrl || "";
            const suffixUrl = availableData?.additionalConfig?.suffixUrl || "";
            setSrc(`${prefixUrl}${url}${suffixUrl}`);
            setTitle(availableData.title);

            for(const menu of user.allowedMenus) {
                if(menu.menus) {
                    for(const menuRow of menu.menus) {
                        if(menuRow.code === key){
                            localBreadcrumb.push({
                                title: <span>{menu.icon ? (menu.icon) : <DynamicIcon type={'SwitcherOutlined'}/>} {menu.title}</span>,
                            });
                            break;
                        }
                    }
                }
            }

            localBreadcrumb.push({
                title: <span>{availableData.icon && (availableData.icon)} {availableData.title}</span>
            });



            setBreadcrumb(localBreadcrumb);
        } else {
            setSrc("");
            setTitle("");
            setBreadcrumb([]);
        }
    }, [key]);

    return (
        <Layout title={title} breadcrumb={breadcrumb}>
            <Card style={{
                width: '100%',
                height: `${vh - heightOffset}px`,
            }}>
                <iframe
                    src={src}
                    title={title}
                    frameBorder={0}
                    style={{
                        marginTop: `0`,
                        width: `100%`,
                        height: `${vh - heightOffset - heightPadding}px`
                    }}
                />
            </Card>
        </Layout>
    );
}

export default DynamicViewer;
