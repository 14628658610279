import MainBackendAxios from '../utils/MainBackendAxios';
import {ApiResponse} from "../models/ApiResponse";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {PaginationData} from "../models/PaginationData";
import {OperationReport} from "../models/OperationReport";

export const OperationReportService = {
    getAllByCurrentUserCompanyAndFiltersAndPaginate: async (bodyParams: GetAllByCurrentUserCompanyAndFiltersAndPaginateRequestDto): Promise<ApiResponse<PaginationData<OperationReport>>> => {
        try {
            const response = await MainBackendAxios.post(`/operation-report/get-all-by-user-company-and-filters-and-paginate`, bodyParams);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};

export interface GetAllByCurrentUserCompanyAndFiltersAndPaginateRequestDto {
    page: number;
    pageSize: number;
    sortField?: string;
    sortOrder?: string;
    term?: string;
}
