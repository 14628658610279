import React, {useEffect, useRef, useState} from 'react';
import {
    Alert, Button,
    Col,
    DatePicker,
    DatePickerProps,
    Divider,
    Form,
    Input, InputNumber,
    Modal,
    Row,
    Select, Typography,
} from "antd";
import {Store} from "../../../../models/Store";
import {Schedule} from "../../../../models/Schedule";
import dayjs, {Dayjs} from "dayjs";
import {ActivationSchema} from "../ActivationRequest";
import {FunctionsHelper} from "../../../../utils/FunctionsHelper";

interface EditPdvConfigurationModalProps {
    isOpen: boolean;
    activation: ActivationSchema;
    handleModalOk: (activation: ActivationSchema) => void;
    handleModalCancel: () => void;
    stores: Store[];
    schedules: Schedule[];
    requestActivationTypeId: number;
    getSubtotal: (activation: ActivationSchema, fixed?: boolean) => string | number;
}

function EditPdvConfigurationModal({ isOpen, handleModalOk, handleModalCancel, stores, activation, schedules, requestActivationTypeId, getSubtotal }: EditPdvConfigurationModalProps) {
    const [form] = Form.useForm();
    const [selectedDates, setSelectedDates] = useState<Dayjs[]>([]);
    const [currentActivation, setCurrentActivation] = useState<ActivationSchema>(activation);

    useEffect(() => {
        form.resetFields();

        const dates = activation.dates.map((record) => {
            return dayjs(record, 'YYYY-MM-DD');
        });

        form.setFieldValue('dates', dates);
        form.setFieldValue('schedule', findScheduleById(currentActivation.scheduleId)?.value);
        setSelectedDates(dates);

    }, [activation]);


    const onFinish = (values: any) => {
        console.log("puta mare", values)
        currentActivation.scheduleId = parseInt(values.schedule);
        handleModalOk(currentActivation);
    };

    const findStoreById = (storeId: number) => {
        return stores.find((record) => {
            return record.id === storeId;
        });
    }

    const onChangeMultipleDatePicker: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
        setSelectedDates(date ? date : []);
        const localActivation = {...currentActivation};
        localActivation.dates = date ? date : [];
        setCurrentActivation(localActivation);
    };

    const getSchedulesByRequestTypeId = () => {
        return schedules.filter((record) => {
            return `${record.requestActivationTypeId}` === `${requestActivationTypeId}`;
        });
    }

    const disabledDates: DatePickerProps['disabledDate'] = (current, { from, type }) => {
        return !(
            [5, 6, 0].includes(current.day())
        );
    };

    const setFirstWeekendCustomDatesAvailable = () => {
        const currentSelectedDates: string[] = ((form.getFieldValue('dates') || []) as Dayjs[]).map((record) => {
            return record.format('YYYY-MM-DD');
        });

        const today = dayjs();
        const dates: string[] = [];

        if(today.day() > 0 && today.day() <= 5) {
            const offset = 5 - today.day()
            dates.push(today.add(offset, 'day').format('YYYY-MM-DD'));
            dates.push(today.add(offset + 1, 'day').format('YYYY-MM-DD'));
            dates.push(today.add(offset + 2, 'day').format('YYYY-MM-DD'));

        }else if (today.day() === 6) {
            dates.push(today.format('YYYY-MM-DD'));
            dates.push(today.add(1, 'day').format('YYYY-MM-DD'));
        }else {
            dates.push(today.format('YYYY-MM-DD'));
        }

        const allDates = Array.from(new Set([...currentSelectedDates, ...dates]));

        const sortedDates = allDates
            .map(date => dayjs(date))
            .sort((a, b) => a.diff(b));

        form.setFieldValue('dates', sortedDates);
        setSelectedDates(sortedDates);
        const localActivation = {...currentActivation};
        localActivation.dates = sortedDates;
        setCurrentActivation(localActivation);
        form.validateFields(['dates']);
    }

    const settWeekendDaysOfMonthCustomDatesAvailable = () => {
        const currentSelectedDates: string[] = ((form.getFieldValue('dates') || []) as Dayjs[]).map((record) => {
            return record.format('YYYY-MM-DD');
        })
        const today = dayjs();
        const startOfMonth = today.startOf('month');
        const endOfMonth = today.endOf('month');
        const weekends: Dayjs[] = [];

        for (let current = startOfMonth; current.isBefore(endOfMonth, 'day'); current = current.add(1, 'day')) {
            if (current.day() === 5 || current.day() === 6 || current.day() === 0) {
                weekends.push(current);
            }
        }

        const filteredWeekends = (weekends.filter(date => FunctionsHelper.isSameOrAfter(date, today))).map((record) => {
            return record.format('YYYY-MM-DD');
        });
        const allDates = Array.from(new Set([...currentSelectedDates, ...filteredWeekends]));

        const sortedDates = allDates
            .map(date => dayjs(date))
            .sort((a, b) => a.diff(b));

        form.setFieldValue('dates', sortedDates);
        setSelectedDates(sortedDates);
        const localActivation = {...currentActivation};
        localActivation.dates = sortedDates;
        setCurrentActivation(localActivation);
        form.validateFields(['dates']);
    };

    const datePresets = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', paddingTop: '4px', paddingBottom: '4px' }}>
                <Typography style={{ textAlign: 'left', fontWeight: 550 }}>Fechas frecuentes:</Typography>
                <Button type="dashed" size="small" onClick={setFirstWeekendCustomDatesAvailable}>Primer fin de semana disponible</Button>
                <Button type="dashed" size="small" onClick={settWeekendDaysOfMonthCustomDatesAvailable}>Fines de semana del mes actual</Button>
            </div>
        );
    };

    const findScheduleById = (scheduleId: number) => {
        const schedule = schedules.find((record) => {
            return record.id === scheduleId;
        });

        return schedule ? {
            value: `${schedule.id}`,
            label: schedule.label
        } : undefined;
    }

    return (
        <Modal
            title={`Editar configuración de punto de venta`}
            open={isOpen}
            onOk={() => { form.submit(); }}
            onCancel={handleModalCancel}
            okText="Guardar"
            maskClosable={false}
            destroyOnClose
            width={800}
        >
            <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                style={{ marginTop: '15px' }}
                initialValues={{
                    schedule: findScheduleById(currentActivation.scheduleId)
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Cadena"
                        >
                            <Input type="text" readOnly style={{ fontWeight: 550 }} defaultValue={findStoreById(currentActivation.storeId)?.businessChain?.name}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Punto de venta"
                        >
                            <Input type="text" readOnly style={{ fontWeight: 550 }}  defaultValue={findStoreById(currentActivation.storeId)?.name}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={20} xs={24}>
                        <Form.Item
                            label="Fechas"
                            name="dates"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (form.getFieldValue('dates') && form.getFieldValue('dates').length >= 3) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Debe seleccionar al menos 3 fechas'));
                                    },
                                })
                            ]}
                        >
                            <DatePicker
                                multiple
                                onChange={onChangeMultipleDatePicker}
                                maxTagCount="responsive"
                                minDate={dayjs()}
                                format={{
                                    format: 'DD/MM/YYYY',
                                    type: 'mask',
                                }}
                                placeholder="Fechas programadas"
                                disabledDate={disabledDates}
                                renderExtraFooter={datePresets}
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={4} xs={24}>
                        <Form.Item
                            label="Días"
                        >
                            <Input placeholder="Total días" readOnly value={selectedDates.length ? selectedDates.length : undefined}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Horario"
                            name="schedule"
                            rules={[
                                { required: true, message: 'El campo es requerido' },
                            ]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                options={getSchedulesByRequestTypeId().map((record) => {
                                    return {
                                        value: `${record.id}`,
                                        label: record.label
                                    };
                                })}
                                showSearch
                                placeholder="Seleccionar horario"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={20} xs={24}>
                        <Form.Item
                            label="Colaboradores"
                            name="numberOfCollaborators"
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                step={1}
                                defaultValue={currentActivation.numberOfCollaborators}
                                placeholder="Ingrese el número de colaboradores"
                                onBlur={(e) => {
                                    const value = e.target.value;
                                    if(value) {
                                        const numericValue = Math.floor(Number(value));
                                        if (numericValue <= 0) {
                                            form.setFieldValue('numberOfCollaborators', 1);
                                        } else {
                                            form.setFieldValue('numberOfCollaborators', numericValue);
                                        }
                                    }else {
                                        form.setFieldValue('numberOfCollaborators', 1);
                                    }
                                }}
                                onChange={(e) => {
                                    if(e && e > 0) {
                                        const localActivation = {...currentActivation};
                                        localActivation.numberOfCollaborators = e;
                                        setCurrentActivation(localActivation);
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={4} xs={24}>
                        <Form.Item
                            label="Subtotal"
                        >
                            <Input placeholder={`0.00`} readOnly style={{ fontWeight: 550 }} value={getSubtotal(currentActivation)}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default EditPdvConfigurationModal;
