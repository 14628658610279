import React, {useRef, useState} from 'react';
import {RequestActivation} from "../../../../../models/RequestActivation";
import {Button, Col, Collapse, Dropdown, MenuProps, message, Row, Space, Table, Typography} from "antd";
import Column from "antd/es/table/Column";
import {Activation} from "../../../../../models/Activation";
import {DownloadOutlined, DownOutlined, UploadOutlined} from "@ant-design/icons";
import {FileService} from "../../../../../services/FileService";
import {FunctionsHelper} from "../../../../../utils/FunctionsHelper";
import {RequestActivationService} from "../../../../../services/RequestActivationService";

interface GeneralDetailTabProps {
    requestActivation: RequestActivation;
    fetchData: (reloadGrid?: boolean) => void;
}

function GeneralDetailTab({ requestActivation, fetchData }: GeneralDetailTabProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [loadingQuoteFile, setLoadingQuoteFile] = useState(false);
    const [loadingOcFile, setLoadingOcFile] = useState(false);
    const [loadingNewOcFile, setLoadingNewOcFile] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const getTaxRateFormatted = () => {
        return (requestActivation.requestTaxRate * 100).toFixed(2);
    }

    const downloadQuoteFile = async () => {
        if(!loadingQuoteFile) {
            setLoadingQuoteFile(true);

            const response = await FileService.getFile(requestActivation.finalQuote!.documentS3Key);

            if(response.success) {
                FunctionsHelper.openFileInOtherTab(response.data.fileBlob);
            }else {
                const error = response.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener el documento de cotización final, por favor inténtalo nuevamente.', 3.5);
            }

            setLoadingQuoteFile(false);
        }
    }

    const downloadOcFile = async () => {
        if(!loadingOcFile) {
            setLoadingOcFile(true);

            const response = await FileService.getFile(requestActivation.purchaseOrderDocumentS3Key!);

            if(response.success) {
                FunctionsHelper.openFileInOtherTab(response.data.fileBlob);
            }else {
                const error = response.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener el documento de orden de compra, por favor inténtalo nuevamente.', 3.5);
            }

            setLoadingOcFile(false);
        }
    }

    const getOcMenuProps = (): MenuProps['items'] => {
        if(requestActivation.requestActivationStatusId === 'REFUSED_PURCHASE_ORDER') {
            return [
                {
                    label: 'Descargar',
                    key: '1',
                    icon: <DownloadOutlined/>,
                    onClick: downloadOcFile,
                    disabled: loadingOcFile
                },
                {
                    type: 'divider'
                },
                {
                    label: 'Volver a subir documento',
                    key: '2',
                    icon: <UploadOutlined/>,
                    onClick: () => {
                        if(!loadingNewOcFile) {
                            fileInputRef.current?.click();
                        }
                    }
                }
            ];
        }

        return [
            {
                label: 'Descargar',
                key: '1',
                icon: <DownloadOutlined/>,
                onClick: downloadOcFile,
                disabled: loadingOcFile
            },
        ];
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        event.target.value = '';

        if (file) {
            setLoadingNewOcFile(true);

            const response = await RequestActivationService.updateStatusToPurchaseOrderToReview(requestActivation.id, file);

            if(response.success) {
                await fetchData(true);
                messageApi.success('Se cargó la orden de compra de manera satisfactoria.', 3.5);
            }else {
                messageApi.error(response.data.message as string || 'Hubo un error al intentar cargar la orden de compra, por favor inténtalo nuevamente.', 3.5);
            }

            setLoadingNewOcFile(false);
        }
    };

    return (
        <div className="request-activation-details-general-tab">
            {contextHolder}
            <Collapse items={[
                {
                    key: '1',
                    label: <span>Detalle de la cotización inicial</span>,
                    children: (
                        <div style={{ width: '100%'}}>
                            <Table
                                <Activation>
                                dataSource={requestActivation.activations}
                                pagination={false}
                                size="small"
                                scroll={{ x: 400, y: 'auto' }}
                                rowKey={(record) => { return record.id }}
                                summary={() => (
                                    <Table.Summary fixed>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={3} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>Subtotal</Typography>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>
                                                    { Number(requestActivation.requestSubTotalAmount).toFixed(2) + ' ' + requestActivation.currency!.code }
                                                </Typography>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={3} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>Impuesto ({getTaxRateFormatted()}%)</Typography>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>
                                                    { Number(requestActivation.requestTaxAmount).toFixed(2) + ' ' + requestActivation.currency!.code }
                                                </Typography>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={3} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>Total</Typography>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>
                                                    { Number(requestActivation.requestTotalAmount).toFixed(2) + ' ' + requestActivation.currency!.code }
                                                </Typography>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                )}
                            >
                                <Column width={320} title="Punto de venta" key="store" render={(row: Activation) => (
                                    <Typography style={{ color: '#707070', fontWeight: 500 }} title={`Dirección: ${row.store!.address}`}>{ row.store!.name }</Typography>
                                )}/>

                                <Column width={115}  align="center" title="Colaboradores" key="numberOfCollaborators" render={(row: Activation) => (
                                    <>{ row.numberOfCollaborators }</>
                                )}/>

                                <Column width={135}  align="center" title="Días de activación" key="dayOfActivation" render={(row: Activation) => (
                                    <>{ row.dates.length }</>
                                )}/>

                                <Column width={180} title="Valor" align="right" key="amount" render={(row: Activation) => (
                                    <>{ Number(row.totalAmount).toFixed(2) + ' ' + requestActivation.currency!.code }</>
                                )}/>
                            </Table>
                        </div>
                    )
                }
            ]} defaultActiveKey={['1']} />

            <Row gutter={24}>
                <Col xs={24} md={24} lg={12} xl={12}  xxl={12} style={{ marginTop: '15px' }}>
                    <Collapse items={[
                        {
                            key: '1',
                            label: <span>Cotización final</span>,
                            children: (
                                <div style={{ width: '100%'}}>
                                    {
                                        !requestActivation.finalQuoteId && (
                                            <span>Sin Cargar</span>
                                        )
                                    }

                                    {
                                        requestActivation.finalQuoteId && (
                                            <Button loading={loadingQuoteFile} type="default" onClick={downloadQuoteFile}><DownloadOutlined /> Descargar cotización final</Button>
                                        )
                                    }
                                </div>
                            )
                        }
                    ]} defaultActiveKey={['1']} />
                </Col>

                <Col xs={24} md={24} lg={12} xl={12}  xxl={12}>
                    <Collapse items={[
                        {
                            key: '1',
                            label: <span>Orden de compra</span>,
                            children: (
                                <div style={{ width: '100%'}}>
                                    {
                                        (!requestActivation.purchaseOrderDocumentS3Key && requestActivation.requestActivationStatusId === 'CONFIRMED')  && (
                                            <Button
                                                loading={loadingNewOcFile}
                                                type="default"
                                                onClick={() => {
                                                    if(!loadingNewOcFile) {
                                                        fileInputRef.current?.click();
                                                    }
                                                }}
                                            >
                                                <UploadOutlined /> Cargar orden de compra
                                            </Button>
                                        )
                                    }
                                    {
                                        (requestActivation.purchaseOrderDocumentS3Key && requestActivation.requestActivationStatusId === 'REFUSED_PURCHASE_ORDER')  && (
                                            <Dropdown menu={{ items: getOcMenuProps() }} trigger={[ 'click' ]}>
                                                <Button loading={loadingOcFile || loadingNewOcFile}>
                                                    <Space>
                                                        Ver detalles
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        )
                                    }
                                    {
                                        (requestActivation.purchaseOrderDocumentS3Key && requestActivation.requestActivationStatusId !== 'REFUSED_PURCHASE_ORDER')  && (
                                            <Button loading={loadingOcFile} type="default" onClick={downloadOcFile}><DownloadOutlined /> Descargar orden de compra</Button>
                                        )
                                    }
                                    {
                                        (!requestActivation.purchaseOrderDocumentS3Key && requestActivation.requestActivationStatusId != 'CONFIRMED')  && (
                                            <span>Sin Cargar</span>
                                        )
                                    }
                                </div>
                            )
                        }
                    ]} defaultActiveKey={['1']}  style={{ marginTop: '15px' }} />
                </Col>
            </Row>

            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
    );
}

export default GeneralDetailTab;
