import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, GetProp, Input, message, Modal, Row, Upload, UploadProps} from "antd";
import TextArea from "antd/es/input/TextArea";
import PhoneInput from "antd-phone-input";
import {Country} from "../../../../../models/Country";
import {CompanyService} from "../../../../../services/CompanyService";
import {FileService} from "../../../../../services/FileService";
import {CompanyContactService} from "../../../../../services/CompanyContactService";
import {CompanyContact} from "../../../../../models/CompanyContact";
import {BrandService} from "../../../../../services/BrandService";
import {Brand} from "../../../../../models/Brand";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

interface DetailBrandModalProps {
    isOpen: boolean;
    id: number;
    handleModalCancel: () => void;
    submit: (id: number, name: string, uploadLogo: boolean, file: File | null) => void;
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

function DetailBrandModal({isOpen, handleModalCancel, id, submit}: DetailBrandModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [brand, setBrand] = useState<Brand>();

    const [imageUrl, setImageUrl] = useState<string>();
    const [imageFile, setImageFile] = useState<FileType | null>(null);
    const [hasLogoChanged, setHasLogoChanged] = useState(false);

    useEffect(() => {
        form.resetFields();

        if(isOpen) {
            fetchData();
        }else {
            form.resetFields();
            setLoading(false);
            setImageUrl(undefined);
            setImageFile(null);
            setBrand(undefined);
            setHasLogoChanged(false);
        }
    }, [isOpen]);

    const fetchData = async () => {
        setLoading(true);

        const response = await BrandService.findById(id);

        if(response.success) {
            setHasLogoChanged(false);

            const localBrand = response.data;
            if(localBrand.logo) {
                const fileResponse = await FileService.getFileAsBase64(localBrand.logo)

                if(fileResponse.success) {
                    setImageUrl(fileResponse.data.base64);
                }
            }

            setBrand(localBrand);

            form.setFieldValue('name', localBrand.name);

            setLoading(false);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la marca, por favor inténtalo nuevamente.', 3.5);
            handleModalCancel();
        }

        setLoading(false);
    }

    const onFinish = async (values: any) => {
        setLoading(true);
        await submit(
            id,
            values.name,
            hasLogoChanged,
            imageFile
        );
        setLoading(false);
    }

    const getBase64 = (img: FileType, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Solo puedes cargar archivos tipo JPG o PNG');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('La imagen no puede superar los 2MB.');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = (info) => {
        const file = info.file.originFileObj as FileType;

        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'removed') {
            setLoading(false);
        }

        if (file && beforeUpload(file)) {
            setImageFile(file);
            getBase64(file, (url) => {
                setImageUrl(url);
                setHasLogoChanged(true);
                setLoading(false);
            });
        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{ loading ? 'Cargando...' : 'Cargar logo'}</div>
        </button>
    );

    return (
        <>
            {contextHolder}

            <Modal
                title={`Ver marca [ID: ${id}]`}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={480}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24} style={{ marginBottom: '15px' }}>
                        <Col xs={24} lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombre"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el nombre"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default DetailBrandModal;
