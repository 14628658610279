import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import PhoneInput from "antd-phone-input";
import {Country} from "../../../models/Country";
import {ItemInterface} from "../../../models/ItemInterface";
import {Company} from "../../../models/Company";
import {Role} from "../../../models/Role";
import {CreateRegularAccountRequestDto} from "../../../services/UserService";
import {ApplicationProfileType} from "../../../models/ApplicationProfileType";
import {Brand} from "../../../models/Brand";
import {BrandService} from "../../../services/BrandService";
import {Store} from "../../../models/Store";
import {StoreService} from "../../../services/StoreService";
import {FunctionsHelper} from "../../../utils/FunctionsHelper";
import {InfoCircleOutlined} from "@ant-design/icons";

interface NewUserModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    countries: Country[];
    companies: Company[];
    roles: Role[];
    applicationProfileTypes: ApplicationProfileType[];
    submit: (params: CreateRegularAccountRequestDto) => void;
}

const SHOW_ALL_BRANDS = 'SHOW_ALL_REQUEST_ACTIVATIONS_BY_COMPANY';
const SHOW_ALL_STORES = 'SHOW_ALL_REQUEST_ACTIVATIONS_BY_BUSINESS_CHAIN'

function NewUserModal({ isOpen, handleModalCancel, countries, companies, roles, applicationProfileTypes, submit }: NewUserModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [applicationProfileTypeSelected, setApplicationProfileTypeSelected] = useState<string>();
    const [countryCodeSelected, setCountryCodeSelected] = useState<string>();
    const [roleSelected, setRoleSelected] = useState<number>();
    const [companySelected, setCompanySelected] = useState<number>();

    const [brands, setBrands] = useState<Brand[]>([]);
    const [stores, setStores] = useState<Store[]>([]);

    useEffect(() => {
        if(isOpen) {
            form.resetFields();
            setApplicationProfileTypeSelected(undefined);
            setCountryCodeSelected(undefined);
            setRoleSelected(undefined);
            setCompanySelected(undefined);
            setBrands([]);
            setStores([]);
        }
    }, [isOpen]);

    const getApplicationProfileTypes = (): ItemInterface[] => {
        return applicationProfileTypes.map((record) => {
            return {
                value: record.id,
                label: record.title
            }
        });
    }

    const getCountries = (): ItemInterface[] => {
        return countries
            .filter((record) => {
                return record.isActive;
            })
            .map((record) => {
            return {
                value: record.code,
                label: record.name
            }
        });
    }

    const getUserTypes = (): ItemInterface[] => {
        if(applicationProfileTypeSelected) {
            return roles
                .filter((record) => {
                    return record.applicationProfileTypeId === applicationProfileTypeSelected
                })
                .map((record) => {
                return {
                    value: record.id,
                    label: record.title
                }
            });
        }
        return [];
    }

    const getCompanies = (): ItemInterface[] => {
        if(countryCodeSelected && applicationProfileTypeSelected) {
            return companies
                .filter((record) => {
                    return record.countryCode === countryCodeSelected
                        && record.applicationProfileTypeId === applicationProfileTypeSelected
                })
                .map((record) => {
                    return {
                        value: record.id,
                        label: record.name
                    }
                });
        }

        return [];
    }

    const isAssociatedToBusinessChain = () => {
        return applicationProfileTypeSelected === 'BUSINESS_CHAIN';
    }

    const findCountryByCode = (code: string) => {
        return countries.find((record) => {
            return record.code === code;
        });
    }

    const onFinish = async (values: any) => {

        setLoading(true);

        await submit({
            name: values.name,
            lastname: values.lastname,
            email: values.email.toLowerCase(),
            companyId: values.company,
            roleId: values.userType,
            dialCode: `+${values.phone.countryCode}`,
            phone: `${values.phone.areaCode}${values.phone.phoneNumber}`,
            storeIds: values.stores || [],
            brandIds: values.brands || []
        });

        setLoading(false);
    }

    const hasPermission = (permission: string) => {
        const role = roles.find((record) => {
            return record.id === roleSelected;
        });

        if(role) {
            return (role.permissions || []).map((record) => {
                return record.code;
            }).includes(permission);
        }

        return false;
    }

    const handleCompanyChange = async (companyId: number) => {
        setCompanySelected(companyId);
        setBrands([]);
        setStores([]);

        const company = companies.find((record) => {
            return record.id === companyId;
        });

        if(company && roleSelected) {
            const role = roles.find((record) => {
                return record.id === roleSelected;
            }) as Role;


            if(['CLIENT', 'BUSINESS_CHAIN'].includes(role.applicationProfileTypeId)) {
                setLoading(true);

                if(role.applicationProfileTypeId === 'CLIENT') {
                    const brandResponse = await BrandService.getByCompany(company.id);

                    if(brandResponse.success) {
                        setBrands(brandResponse.data);
                    }else {
                        const error = brandResponse.data;
                        messageApi.error(error.message as string || 'Hubo un error al intentar cargar el lista de marcas, por favor inténtalo nuevamente.', 3.5);
                    }

                }

                if(role.applicationProfileTypeId === 'BUSINESS_CHAIN') {
                    const storeResponse = await StoreService.getByBusinessChain(company.businessChain!.id);

                    if(storeResponse.success) {
                        setStores(storeResponse.data);
                    }else {
                        const error = storeResponse.data;
                        messageApi.error(error.message as string || 'Hubo un error al intentar cargar el lista de tiendas, por favor inténtalo nuevamente.', 3.5);
                    }
                }

                setLoading(false);
            }

            form.resetFields(['stores', 'brands']);
        }
    }

    const showBrands = () => {
        if(companySelected && roleSelected) {
            const role = roles.find((record) => {
                return record.id === roleSelected;
            }) as Role;

            return role.applicationProfileTypeId === 'CLIENT';
        }

        return false;
    }

    const showStores = () => {
        if(companySelected && roleSelected) {
            const role = roles.find((record) => {
                return record.id === roleSelected;
            }) as Role;

            return role.applicationProfileTypeId === 'BUSINESS_CHAIN';
        }

        return false;
    }

    return (
        <>
            {contextHolder}

            <Modal
                title="Crear usuario"
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={640}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24} style={{ marginBottom: '18px' }}>
                        <Col xs={24}>
                            <Alert message="La contraseña del usuario creado se genera de manera aleatoria y es enviada por correo electrónico." type="info" showIcon />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="country"
                                label="País"
                                rules={[{ required: true, message: 'Debes de seleccionar un país' }]}
                            >
                                <Select
                                    options={getCountries()}
                                    placeholder="-Seleccione-"
                                    onChange={(v) => {
                                        setCountryCodeSelected(v);
                                        setCompanySelected(undefined);
                                        setStores([]);
                                        setBrands([]);
                                        form.resetFields(['company', 'stores', 'brands']);
                                        const currentPhone = form.getFieldValue('phone');
                                        const country = findCountryByCode(v);

                                        if(!currentPhone.areaCode && !currentPhone.phoneNumber && country) {
                                            form.setFieldValue('phone', {...form.getFieldValue('phone'), countryCode: parseInt(country.dialCode)})
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="applicationProfileType"
                                label="Tipo de usuario"
                                rules={[{ required: true, message: 'Debes de seleccionar un tipo de usuario' }]}
                            >
                                <Select
                                    options={getApplicationProfileTypes()}
                                    placeholder="-Seleccione-"
                                    onChange={(v) => {
                                        setApplicationProfileTypeSelected(v);
                                        setCompanySelected(undefined);
                                        setStores([]);
                                        setBrands([]);
                                        form.resetFields(['company', 'userType', 'stores', 'brands']);
                                    }}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="userType"
                                label="Rol"
                                rules={[{ required: true, message: 'Debes de seleccionar un rol' }]}
                            >
                                <Select
                                    options={getUserTypes()}
                                    placeholder="-Seleccione-"
                                    disabled={loading || !applicationProfileTypeSelected}
                                    onChange={(v) => {
                                        setRoleSelected(v);
                                        setCompanySelected(undefined);
                                        setStores([]);
                                        setBrands([]);
                                        form.resetFields(['company', 'stores', 'brands']);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="company"
                                label={isAssociatedToBusinessChain() ? 'Cadena' : 'Compañía'}
                                rules={[{ required: true, message: 'Debes de seleccionar una opción' }]}
                            >
                                <Select
                                    options={getCompanies()}
                                    placeholder="-Seleccione-"
                                    disabled={loading || !applicationProfileTypeSelected || !countryCodeSelected}
                                    onChange={async (v) => {
                                        await handleCompanyChange(v);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        showBrands() && (
                            <Row gutter={24}>
                                <Col xs={24}>
                                    {
                                        !hasPermission(SHOW_ALL_BRANDS) && (
                                            <Form.Item
                                                name="brands"
                                                label="Marcas"
                                            >
                                                <Select
                                                    options={brands.map((record) => {
                                                        return {
                                                            value: record.id,
                                                            label: record.name
                                                        };
                                                    })}
                                                    placeholder="-Seleccione-"
                                                    disabled={loading}
                                                    allowClear
                                                    mode="multiple"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        FunctionsHelper.normalizeText((option?.label ?? '')).includes(FunctionsHelper.normalizeText(input))
                                                    }
                                                />
                                            </Form.Item>
                                        )
                                    }

                                    {
                                        hasPermission(SHOW_ALL_BRANDS) && (
                                            <Form.Item
                                                label="Marcas"
                                            >
                                                <Input type="text" defaultValue="Acceso a todas las marcas de la compañía" readOnly prefix={<InfoCircleOutlined />}/>
                                            </Form.Item>
                                        )
                                    }
                                </Col>
                            </Row>
                        )
                    }

                    {
                        showStores() && (
                            <Row gutter={24}>
                                <Col xs={24}>
                                    {
                                        !hasPermission(SHOW_ALL_STORES) && (
                                            <Form.Item
                                                name="stores"
                                                label="Tiendas"
                                            >
                                                <Select
                                                    options={stores.map((record) => {
                                                        return {
                                                            value: record.id,
                                                            label: record.name
                                                        };
                                                    })}
                                                    placeholder="-Seleccione-"
                                                    disabled={loading}
                                                    allowClear
                                                    mode="multiple"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        FunctionsHelper.normalizeText((option?.label ?? '')).includes(FunctionsHelper.normalizeText(input))
                                                    }
                                                />
                                            </Form.Item>
                                        )
                                    }
                                    {
                                        hasPermission(SHOW_ALL_STORES) && (
                                            <Form.Item
                                                label="Tiendas"
                                            >
                                                <Input type="text" defaultValue="Acceso a todas las tiendas de la cadena" readOnly prefix={<InfoCircleOutlined />}/>
                                            </Form.Item>
                                        )
                                    }
                                </Col>
                            </Row>
                        )
                    }

                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="name"
                                label="Nombres"
                                rules={[{ required: true, message: 'Debes de ingresar los nombres.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los nombres"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="lastname"
                                label="Apellidos"
                                rules={[{ required: true, message: 'Debes de ingresar los apellidos.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los apellidos" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="email"
                                label="Correo"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: "Debes de ingresar un correo válido",
                                    }
                                ]}
                            >
                                <Input type="email" disabled={loading} placeholder="Ingrese el correo"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="phone"
                                label="Teléfono"
                                rules={[
                                    { required: true, message: 'El teléfono es requerido' },
                                    {
                                        validator: (_, value) => {
                                            if (value && value.valid()) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Debes ingresar un número de teléfono válido.'));
                                        },
                                    },
                                ]}
                            >
                                <PhoneInput enableSearch searchNotFound="No se encontró el país" searchPlaceholder="Buscar país" allowClear placeholder="Ingrese el teléfono" disabled={loading}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewUserModal;
