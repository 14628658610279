import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Brand} from "../models/Brand";
import {GenericUpdateOrDeleteResponseDto} from "../models/dtos/GenericUpdateOrDeleteResponseDto";
import {GenericCreateResponseDto} from "../models/dtos/GenericCreateResponseDto";
import {CreateWithClientProfileRequestDto, UpdateWithClientProfileRequestDto} from "./CompanyService";

export const BrandService = {
    getByCompany: async (companyId: number): Promise<ApiResponse<Brand[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/brand/get-by-company/${companyId}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as Brand[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAllAvailableByCurrentUserAndCompany: async (): Promise<ApiResponse<Brand[]>>  => {
        try {
            const response = await MainBackendAxios.post(`/brand/get-all-available-by-current-user-and-company`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as Brand[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    findById: async (id: number): Promise<ApiResponse<Brand>>  => {
        try {
            const response = await MainBackendAxios.get(`/brand/find-by-id/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    delete: async (id: number): Promise<ApiResponse<GenericUpdateOrDeleteResponseDto>>  => {
        try {
            const response = await MainBackendAxios.delete(`/brand/delete/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    create: async (body: CreateBrandRequestDto, file: File | null): Promise<ApiResponse<GenericCreateResponseDto>> => {
        try {
            const formData = new FormData();
            formData.append('name', body.name);
            formData.append('companyId', `${body.companyId}`);

            if(file) {
                formData.append('file', file);
            }

            const response = await MainBackendAxios.post(`/brand/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    update: async (id: number, body: UpdateBrandRequestDto, file?: File | null): Promise<ApiResponse<GenericUpdateOrDeleteResponseDto>> => {
        try {
            const formData = new FormData();
            formData.append('name', body.name);
            formData.append('uploadLogo', body.uploadLogo ? 'true' : 'false');

            if(body.uploadLogo && file) {
                formData.append('file', file);
            }

            const response = await MainBackendAxios.put(`/brand/update/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};

interface CreateBrandRequestDto {
    companyId: number;
    name: string;
}

interface UpdateBrandRequestDto {
    name: string;
    uploadLogo: boolean;
}
