import React, {useEffect, useRef, useState} from 'react';
import {
    Alert, Button,
    Col,
    DatePicker,
    DatePickerProps,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Select, TimeRangePickerProps,
    TreeSelect, Typography,
} from "antd";
import {Store} from "../../../../models/Store";
import {Schedule} from "../../../../models/Schedule";
import dayjs, {Dayjs} from "dayjs";
import {ActivationSchema} from "../ActivationRequest";
import {FunctionsHelper} from "../../../../utils/FunctionsHelper";

interface NewPdvConfigurationModalProps {
    isOpen: boolean;
    handleModalOk: (storeIds: number[], dates: Dayjs[], scheduleId: number) => void;
    handleModalCancel: () => void;
    stores: Store[];
    requestActivationTypeId: number;
    schedules: Schedule[];
    activations: ActivationSchema[];
}

const { SHOW_PARENT } = TreeSelect;

function NewPdvConfigurationModal({ isOpen, handleModalOk, handleModalCancel, stores, requestActivationTypeId, schedules, activations }: NewPdvConfigurationModalProps) {
    const [form] = Form.useForm();
    const [selectedDates, setSelectedDates] = useState<Dayjs[]>([]);

    useEffect(() => {
        form.resetFields();
    }, []);

    const onFinish = (values: any) => {
        let storeIds: number[] = values.stores.filter((record: string) => {
            return record.startsWith('store');
        }).map((record: string) => {
            return parseInt(record.split('-')[1]);
        });

        const chainValues: string[] = values.stores.filter((record: string) => {
            return record.startsWith('chain');
        });

        for(const chainValue of chainValues) {
            const chainBusiness =  getAvailableStoresWithTreeDataFormat().find((record: any) => {
                return record.value === chainValue;
            });

            if(chainBusiness) {
                const storeIdsOfChain: number[] = chainBusiness.children.map((record: any) => {
                    return parseInt(record.value.split('-')[1]);
                });
                storeIds = storeIds.concat(storeIdsOfChain);
            }
        }

        handleModalOk(storeIds, selectedDates, parseInt(values.schedule));
    };

    const getAvailableStoresWithTreeDataFormat = () => {
        const availableStores = stores.filter((record) => {
            return !activations.map((activation) => {
                return activation.storeId
            }).includes(record.id);
        });

        const treeData: any[] = [];

        const chainMap: any = {};

        availableStores.forEach(store => {
            const chainId = store.businessChain!.id;
            if (!chainMap[chainId]) {
                chainMap[chainId] = {
                    title: store.businessChain!.name,
                    key: `chain-${store.businessChain!.name}`,
                    value: `chain-${chainId}`,
                    children: []
                };
                treeData.push(chainMap[chainId]);
            }

            chainMap[chainId].children.push({
                title: store.name,
                key: `store-${store.name}`,
                value: `store-${store.id}`
            });
        });

        return treeData;
    };

    const getSchedulesByRequestTypeId = () => {
        return schedules.filter((record) => {
            return `${record.requestActivationTypeId}` === `${requestActivationTypeId}`;
        });
    }

    const onChangeMultipleDatePicker: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
        setSelectedDates(date ? date : []);
    };

    const disabledDates: DatePickerProps['disabledDate'] = (current, { from, type }) => {
        return !(
            [5, 6, 0].includes(current.day())
        );
    };

    const setFirstWeekendCustomDatesAvailable = () => {
        const currentSelectedDates: string[] = ((form.getFieldValue('dates') || []) as Dayjs[]).map((record) => {
            return record.format('YYYY-MM-DD');
        });

        const today = dayjs();
        const dates: string[] = [];

        if(today.day() > 0 && today.day() <= 5) {
            const offset = 5 - today.day()
            dates.push(today.add(offset, 'day').format('YYYY-MM-DD'));
            dates.push(today.add(offset + 1, 'day').format('YYYY-MM-DD'));
            dates.push(today.add(offset + 2, 'day').format('YYYY-MM-DD'));

        }else if (today.day() === 6) {
            dates.push(today.format('YYYY-MM-DD'));
            dates.push(today.add(1, 'day').format('YYYY-MM-DD'));
        }else {
            dates.push(today.format('YYYY-MM-DD'));
        }

        const allDates = Array.from(new Set([...currentSelectedDates, ...dates]));

        const sortedDates = allDates
            .map(date => dayjs(date))
            .sort((a, b) => a.diff(b));

        form.setFieldValue('dates', sortedDates);
        setSelectedDates(sortedDates);
        form.validateFields(['dates']);
    }

    const settWeekendDaysOfMonthCustomDatesAvailable = () => {
        const currentSelectedDates: string[] = ((form.getFieldValue('dates') || []) as Dayjs[]).map((record) => {
            return record.format('YYYY-MM-DD');
        })
        const today = dayjs();
        const startOfMonth = today.startOf('month');
        const endOfMonth = today.endOf('month');
        const weekends: Dayjs[] = [];

        for (let current = startOfMonth; current.isBefore(endOfMonth, 'day'); current = current.add(1, 'day')) {
            if (current.day() === 5 || current.day() === 6 || current.day() === 0) {
                weekends.push(current);
            }
        }

        const filteredWeekends = (weekends.filter(date => FunctionsHelper.isSameOrAfter(date, today))).map((record) => {
            return record.format('YYYY-MM-DD');
        });
        const allDates = Array.from(new Set([...currentSelectedDates, ...filteredWeekends]));

        const sortedDates = allDates
            .map(date => dayjs(date))
            .sort((a, b) => a.diff(b));

        form.setFieldValue('dates', sortedDates);
        setSelectedDates(sortedDates);
        form.validateFields(['dates']);
    };

    const datePresets = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', paddingTop: '4px', paddingBottom: '4px' }}>
                <Typography style={{ textAlign: 'left', fontWeight: 550 }}>Fechas frecuentes:</Typography>
                <Button type="dashed" size="small" onClick={setFirstWeekendCustomDatesAvailable}>Primer fin de semana disponible</Button>
                <Button type="dashed" size="small" onClick={settWeekendDaysOfMonthCustomDatesAvailable}>Fines de semana del mes actual</Button>
            </div>
        );
    }

    return (
        <Modal
            title="Puntos de venta"
            open={isOpen}
            onOk={() => { form.submit(); }}
            onCancel={handleModalCancel}
            okText="Guardar"
            maskClosable={false}
            destroyOnClose
            width={800}
        >
            <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

            <Alert message="Complete los siguientes campos para agregar los PDV con su respectiva programación." type="info" showIcon />

            <Form form={form} layout="vertical" onFinish={onFinish} style={{ marginTop: '15px' }}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="stores"
                            label="Seleccionar cadenas o PDV"
                            rules={[
                                { required: true, message: 'El campo es requerido' },
                            ]}
                        >
                            <TreeSelect
                                treeData={getAvailableStoresWithTreeDataFormat()}
                                treeCheckable
                                showCheckedStrategy={SHOW_PARENT}
                                placeholder="Buscar por nombre de cadena o PDV"
                                style={{ width: '100%' }}
                                treeDefaultExpandAll
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={20} xs={24}>
                        <Form.Item
                            label="Fechas"
                            name="dates"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (form.getFieldValue('dates') && form.getFieldValue('dates').length >= 3) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Debe seleccionar al menos 3 fechas'));
                                    },
                                })
                            ]}
                        >
                            <DatePicker
                                multiple
                                onChange={onChangeMultipleDatePicker}
                                maxTagCount="responsive"
                                minDate={dayjs()}
                                format={{
                                    format: 'DD/MM/YYYY',
                                    type: 'mask',
                                }}
                                placeholder="Fechas programadas"
                                disabledDate={disabledDates}
                                renderExtraFooter={datePresets}
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={4} xs={24}>
                        <Form.Item
                            label="Días"
                        >
                            <Input placeholder="Total días" readOnly value={selectedDates.length ? selectedDates.length : undefined}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Horario"
                            name="schedule"
                            rules={[
                                { required: true, message: 'El campo es requerido' },
                            ]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                options={getSchedulesByRequestTypeId().map((record) => {
                                    return {
                                        value: `${record.id}`,
                                        label: record.label
                                    };
                                })}
                                showSearch
                                placeholder="Seleccionar horario"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default NewPdvConfigurationModal;
