import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, message, Modal, Row} from "antd";
import AdminRequestActivationDetailModalSkeleton from "./AdminRequestActivationDetailModalSkeleton";
import AdminLeftSidebar from "./AdminLeftSidebar";
import AdminGeneralDetailTab from "./AdminGeneralDetailTab";
import AdminScheduleTab from "./AdminScheduleTab";
import AdminClientNoteTab from "./AdminClientNoteTab";
import {RequestActivation} from "../../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../../config/ThemeDashboardLayoutConfig";
import {RequestActivationStatus} from "../../../../models/RequestActivationStatus";
import {RequestActivationService} from "../../../../services/RequestActivationService";
import AdminBusinessChainNoteTab from "./AdminBusinessChainNoteTab";
import {RequestActivationLog} from "../../../../models/RequestActivationLog";
import {RequestActivationLogService} from "../../../../services/RequestActivationLogService";
import UploadQuoteModal from "./UploadQuoteModal";
import {
    CalendarOutlined,
    CreditCardOutlined,
    FieldTimeOutlined,
    FileOutlined,
    FileTextOutlined
} from "@ant-design/icons";
import AdminTrackTab from "./AdminTrackTab";

import './AdminRequestActivationDetailModal.scss';

interface AdminRequestActivationDetailModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    selectedRequestActivation: RequestActivation;
    requestActivationStatuses: RequestActivationStatus[];
    reloadGrid: (currentPage?: number) => void;
    showRefusedStatusModalOpen: (row: RequestActivation, callback?: () => void) => void;
    showRefusedOcModalOpen: (row: RequestActivation, callback?: () => void) => void;
}

function AdminRequestActivationDetailModal({ selectedRequestActivation, isOpen, handleModalCancel, requestActivationStatuses, reloadGrid, showRefusedStatusModalOpen, showRefusedOcModalOpen }: AdminRequestActivationDetailModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const themeConfig = useThemeLayoutConfig();
    const staticHeight = '65vh';
    const [activeTabKey, setActiveTabKey] = useState<string>('general_details');
    const [requestActivation, setRequestActivation] = useState<RequestActivation>();
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState<RequestActivationLog[]>([]);
    const [isOpenUploadQuoteModal, setIsOpenUploadQuoteModal] = useState(false);

    useEffect(() => {
        setRequestActivation(undefined);

        if(isOpen) {
            setActiveTabKey('general_details');
            fetchData();
        }
    }, [isOpen])

    const fetchData = async (reloadGridValue: boolean = false) => {
        setLoading(true);
        const [
            requestActivationResponse,
            requestActivationLogResponse
        ] = await Promise.all([
            RequestActivationService.findById(selectedRequestActivation.id),
            RequestActivationLogService.getByRequestActivation(selectedRequestActivation.id, { isRealStatus: true })
        ]);

        if(requestActivationResponse.success && requestActivationLogResponse.success) {
            setRequestActivation(requestActivationResponse.data);
            setLogs(requestActivationLogResponse.data);
            if(reloadGridValue) {
                reloadGrid();
            }
        }else {
            if(!requestActivationResponse.success) {
                const error = requestActivationResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar cancelar la solicitud de activación, por favor inténtalo nuevamente.', 3.5);
            }

            if(!requestActivationLogResponse.success) {
                const error = requestActivationLogResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar cancelar la solicitud de activación, por favor inténtalo nuevamente.', 3.5);
            }
        }
        setLoading(false);
    }

    const tabList = [
        { key: 'general_details', label: 'Detalles generales', icon: <CreditCardOutlined /> },
        { key: 'schedule', label: 'Programación', icon: <CalendarOutlined /> },
        { key: 'client_notes', label: 'Notas del cliente', icon: <FileOutlined /> },
        { key: 'business_chain_notes', label: 'Notas de la cadena', icon: <FileTextOutlined /> },
        { key: 'tracking', label: 'Seguimiento', icon: <FieldTimeOutlined /> },
    ];

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const showUploadQuoteModal = () => {
        setIsOpenUploadQuoteModal(true);
    }

    const closeUploadQuoteModal = () => {
        setIsOpenUploadQuoteModal(false);
    }

    const showRefusedStatusModalOpenWrapper = () => {
        showRefusedStatusModalOpen(selectedRequestActivation, fetchData);
    }

    const showRefusedOcModalOpenWrapper = () => {
        showRefusedOcModalOpen(selectedRequestActivation, fetchData);
    }

    const tabsContent: Record<string, React.ReactNode> = {
        general_details: <AdminGeneralDetailTab requestActivation={requestActivation!} showUploadQuoteModal={showUploadQuoteModal} showRefusedOcModalOpen={showRefusedOcModalOpenWrapper} fetchData={fetchData} showRefusedStatusModalOpen={showRefusedStatusModalOpenWrapper}/>,
        schedule: <AdminScheduleTab requestActivation={requestActivation!} />,
        client_notes: <AdminClientNoteTab requestActivation={requestActivation!} />,
        business_chain_notes: <AdminBusinessChainNoteTab requestActivation={requestActivation!} />,
        tracking: <AdminTrackTab requestActivation={requestActivation!} logs={logs}/>
    };

    return (
        <>
            { contextHolder }

            <Modal
                title={<span>Solicitud N°: <span style={{ color: themeConfig.token?.colorPrimary }} >{selectedRequestActivation.id}</span></span>}
                open={isOpen}
                onCancel={handleModalCancel}
                okText="Guardar"
                maskClosable={false}
                destroyOnClose
                width="90%"
                footer={null}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                {
                    (!requestActivation || loading) && (
                        <AdminRequestActivationDetailModalSkeleton/>
                    )
                }

                {
                    !(!requestActivation || loading) && (
                        <Row gutter={24}>
                            <Col xs={24} md={24} lg={6} xl={6}  xxl={6} style={{ marginBottom: '15px' }}>
                                <Card style={{ height: staticHeight, overflowX: "auto" }}>
                                    <AdminLeftSidebar
                                        requestActivation={requestActivation}
                                        requestActivationStatuses={requestActivationStatuses}
                                    />
                                </Card>
                            </Col>

                            <Col xs={24} md={24} lg={18} xl={18} xxl={18} style={{ marginBottom: '15px' }}>
                                <Card
                                    style={{
                                        height: staticHeight,
                                        overflowX: "auto",
                                    }}
                                    tabList={tabList}
                                    tabProps={{
                                        size: 'middle',
                                    }}
                                    activeTabKey={activeTabKey}
                                    onTabChange={onTabChange}
                                    className={ ['client_notes', 'business_chain_notes'].includes(activeTabKey) ? 'admin-request-activation-detail-modal-card-body-notes-padding' : '' }
                                >
                                    {requestActivation && tabsContent[activeTabKey]}
                                </Card>
                            </Col>
                        </Row>
                    )
                }
            </Modal>

            {/* Modals */}
            {
                requestActivation && (
                    <UploadQuoteModal
                        isOpen={isOpenUploadQuoteModal}
                        handleModalCancel={closeUploadQuoteModal}
                        selectedRequestActivation={requestActivation}
                        fetchParenData={fetchData}
                    />
                )
            }
        </>
    );
}

export default AdminRequestActivationDetailModal;
