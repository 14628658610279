import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Dropdown, MenuProps, message, Modal, Row, Table, TableProps, Tag} from "antd";
import {Company} from "../../../../../models/Company";
import {TableParams} from "../../../../../models/TableParams";
import {PAGE_SIZE} from "../../../../../config/Constants";
import {FunctionsHelper} from "../../../../../utils/FunctionsHelper";
import Column from "antd/es/table/Column";
import {
    EyeOutlined,
    MoreOutlined,
    PlusOutlined, TagsOutlined,
} from "@ant-design/icons";
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/es/input";
import {Brand} from "../../../../../models/Brand";
import {BrandService} from "../../../../../services/BrandService";
import dayjs from "dayjs";
import NewBrandModal from "./NewBrandModal";
import DetailBrandModal from "./DetailBrandModal";

interface BrandManagementModalProps {
    parent: Company;
    isOpen: boolean;
    handleModalCancel: () => void;
}

function BrandManagementModal({ parent, isOpen, handleModalCancel }: BrandManagementModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<Brand[]>([]);
    const [filteredDataSource, setFilteredDataSource] = useState<Brand[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: undefined,
        sortOrder: undefined
    });

    /* Filters */
    const [term, setTerm] = useState('');

    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<Brand | undefined>(undefined);

    useEffect(() => {
        fetchData(tableParams.pagination?.current);
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    useEffect(() => {
        if(isOpen) {
            fetchDataFromServer(true);
        }else {
            setLoading(false);
            setFilteredDataSource([]);
            setDataSource([]);
        }
    }, [isOpen]);

    const fetchDataFromServer = async (withLoading: boolean = false) => {
        if(withLoading) {
            setLoading(true);
        }
        const response = await BrandService.getByCompany(parent.id);

        if(response.success) {
            setDataSource(response.data);
            fetchData(1, response.data);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la compañía, por favor inténtalo nuevamente.', 3.5);
            handleModalCancel();
        }

        if(withLoading) {
            setLoading(false);
        }
    }

    const fetchData = (currentPage: number = 1, data?: Brand[]) => {
        const termFilter = FunctionsHelper.normalizeText(term.trim());

        let filteredData  = data !== undefined ? data : [...dataSource];

        filteredData = filteredData.sort((a, b) => {
            return b.id - a.id;
        });

        if(termFilter.length > 0) {
            filteredData  = filteredData.filter((record) => {
                return FunctionsHelper.normalizeText(record.name).includes(termFilter);
            });
        }

        const pageSize = tableParams.pagination?.pageSize || PAGE_SIZE;
        const total = filteredData.length;
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = filteredData.slice(startIndex, endIndex);

        setFilteredDataSource(paginatedData);
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: currentPage,
                total: total
            }
        });
    }

    const handleTableChange: TableProps<Brand>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setFilteredDataSource([]);
        }
    };

    const getDropdownMenu = (row: Brand): MenuProps['items'] => {
        const menus: MenuProps['items'] = [];

        menus.push({
            key: '1',
            label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver detalle</span>,
            onClick: () => { showDetailModalOpen(row); }
        });

        return menus;
    }

    const showDetailModalOpen = (row: Brand) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const showRegisterModalOpen = () => {
        setIsRegisterModalOpen(true);
    }

    const closeRegisterModalOpen = () => {
        setIsRegisterModalOpen(false);
    }

    const closeDetailModalOpen = () => {
        setIsDetailModalOpen(false);
    }

    const createNewBrand = async (companyId: number, name: string, file: File | null) => {
        setLoading(true);

        const response = await BrandService.create({
            companyId, name
        }, file);

        if(response.success) {
            await Promise.all([
                fetchDataFromServer(),
            ]);
            setIsRegisterModalOpen(false);
            messageApi.success(`Se creo satisfactoriamente la marca con ID: ${response.data.createdId}`);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar crear la marca, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    const updateBrand = async (id: number, name: string, uploadLogo: boolean, file: File | null) => {
        setLoading(true);

        const response = await BrandService.update(id, {
            name, uploadLogo
        }, file);

        if(response.success) {
            await Promise.all([
                fetchDataFromServer(),
            ]);
            setIsDetailModalOpen(false);
            messageApi.success(`Se guardaron los cambios satisfactoriamente de la marca con ID: ${id}`);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar actualizar los datos de la marca, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={<><TagsOutlined/> Marcas {parent.name}</>}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={1200}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <div className="filter-container" style={{ marginBottom: '18px' }}>
                    <Row gutter={[24, 16]}>
                        <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                            <label className="filter-search-container">Filtrar por: <Search placeholder="Nombre de la marca" onSearch={onSearch} disabled={loading} allowClear/></label>
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                            <Button type="primary" onClick={showRegisterModalOpen}  disabled={loading}><PlusOutlined /> Crear marca</Button>
                        </Col>
                    </Row>
                </div>

                <Table
                    <Brand>
                    dataSource={filteredDataSource}
                    bordered
                    loading={loading}
                    pagination={tableParams.pagination}
                    size="small"
                    scroll={{ x: 768 }}
                    rowKey={(record) => { return record.id }}
                    onChange={handleTableChange}
                >
                    <Column width={60} fixed="left" align="center" title="ID" dataIndex="id" key="id"/>

                    <Column ellipsis title="Nombre" key="name" render={(row: Brand) => (
                        <>{row.name}</>
                    )}/>

                    <Column width={160} title="Fecha de creación" key="createdAt" render={(row: Brand) => (
                        <>{dayjs(row.createdAt).format('DD/MM/YYYY h:mm A')}</>
                    )}/>

                    <Column width={60} fixed="right" align="center" title="" key="actions" render={(row) => (
                        <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                            <Button size="small"><MoreOutlined /></Button>
                        </Dropdown>
                    )} />
                </Table>
            </Modal>

            {/*Modals*/}
            <NewBrandModal
                isOpen={isRegisterModalOpen}
                handleModalCancel={closeRegisterModalOpen}
                parentId={parent.id}
                submit={createNewBrand}
            />

            {
                selectedRow && (
                    <DetailBrandModal
                        isOpen={isDetailModalOpen}
                        id={selectedRow.id}
                        handleModalCancel={closeDetailModalOpen}
                        submit={updateBrand}
                    />
                )
            }
        </>
    );
}

export default BrandManagementModal;
