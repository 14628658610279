import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Dropdown, MenuProps, message, Modal, Row, Table, TableProps, Tag, Tooltip} from "antd";
import {TableParams} from "../../../models/TableParams";
import {PAGE_SIZE} from "../../../config/Constants";
import {Company} from "../../../models/Company";
import {CountryService} from "../../../services/CountryService";
import {CompanyService} from "../../../services/CompanyService";
import {SearchProps} from "antd/es/input";
import {
    AlignLeftOutlined,
    EyeOutlined,
    MoreOutlined,
    PlusOutlined,
    SettingOutlined,
    TagsOutlined,
    TeamOutlined
} from "@ant-design/icons";
import Search from "antd/es/input/Search";
import Column from "antd/es/table/Column";
import Layout from "../../../components/Layout/Layout";
import {Country} from "../../../models/Country";
import NewClientModal from "./components/NewClientModal";
import DetailClientModal from "./components/DetailClientModal";
import ContactManagementModal from "./components/ContactManagement/ContactManagementModal";
import BrandManagementModal from "./components/BrandManagement/BrandManagementModal";
import {Menu} from "../../../models/Menu";
import {MenuService} from "../../../services/MenuService";
import MenuDetailModal from "./components/MenuDetailModal";

function Client() {
    const [modal, contextModalHolder] = Modal.useModal();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<Company[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: undefined,
        sortOrder: undefined
    });

    /* Filters */
    const [term, setTerm] = useState('');

    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isNewClientModalOpen, setIsNewClientModalOpen] = useState(false);
    const [isContactManagementOpen, setIsContactManagementOpen] = useState(false);
    const [isBrandManagementOpen, setIsBrandManagementOpen] = useState(false);
    const [isMenuDetailOpen, setIsMenuDetailOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<Company | undefined>(undefined);
    const [countries, setCountries] = useState<Country[]>([]);
    const [menus, setMenus] = useState<Menu[]>([]);

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const [
            countryResponse,
            menuResponse
        ] = await Promise.all([
            CountryService.getAll(),
            MenuService.getAll(),
            fetchData()
        ]);

        if(countryResponse.success && menuResponse.success) {
            setCountries(countryResponse.data);
            setMenus(menuResponse.data);
            setLoading(false);
        }else {
            if(!countryResponse.success) {
                const error = countryResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los países, por favor inténtalo nuevamente.', 3.5);
            }

            if(!menuResponse.success) {
                const error = menuResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los menús, por favor inténtalo nuevamente.', 3.5);
            }
        }
    }

    const createNewClient = async (name: string, countryCode: string, prefix: string, taxes: number, description: string | null, logo: File | null) => {
        const response = await CompanyService.createByClientProfile({
            name,
            countryCode,
            activationRequestPrefix: prefix,
            currencyCode: findCountryByCode(countryCode)!.mainCurrency || '',
            taxRate: taxes,
            description
        }, logo);

        if(response.success) {
            await fetchData(1);
            messageApi.success(<span>Se creó al cliente <b>{name}</b> con ID <b>{response.data.createdId}</b> de manera satisfactoria.</span>, 3.5);
            setIsNewClientModalOpen(false);
        }else {
            messageApi.error(response.data.message as string || 'Hubo un error al intentar crear al cliente, por favor inténtalo nuevamente.', 3.5);
        }
    }

    const fetchData = async (currentPage?: number) => {
        setTableLoading(true);

        const termFilter = term.trim() || undefined;

        const datasourceResponse = await CompanyService.getAllByClientProfileAndFilters({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            sortField: tableParams.sortField,
            sortOrder: tableParams.sortOrder,
            term: termFilter
        });

        if(datasourceResponse.success) {
            const data = datasourceResponse.data;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = datasourceResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const handleTableChange: TableProps<Company>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const getDropdownMenu = (row: Company): MenuProps['items'] => {
        const menus: MenuProps['items'] = [];

        menus.push({
            key: '1',
            label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver detalle</span>,
            onClick: () => { showDetailModalOpen(row); }
        });

        menus.push({
            key: '2',
            label: <span><AlignLeftOutlined style={{ marginRight: '8px' }}/> Menus</span>,
            onClick: () => { showMenuDetailModalOpen(row); }
        });

        menus.push({
            key: '3',
            label: <span><TeamOutlined style={{ marginRight: '8px' }}/> Contactos</span>,
            onClick: () => { showContactModalOpen(row); }
        });

        menus.push({
            key: '4',
            label: <span><TagsOutlined style={{ marginRight: '8px' }}/> Marcas</span>,
            onClick: () => { showBrandModalOpen(row); }
        });

        return menus;
    }

    const showDetailModalOpen = (row: Company) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const showContactModalOpen = (row: Company) => {
        setSelectedRow(row);
        setIsContactManagementOpen(true);
    }

    const showMenuDetailModalOpen = (row: Company) => {
        setSelectedRow(row);
        setIsMenuDetailOpen(true);
    }

    const showBrandModalOpen = (row: Company) => {
        setSelectedRow(row);
        setIsBrandManagementOpen(true);
    }

    const showNewClientModalOpen = () => {
        if(!tableLoading) {
            setIsNewClientModalOpen(true);
        }
    }

    const closeNewClientModalOpen = () => {
        setIsNewClientModalOpen(false);
    }

    const closeDetailClientModalOpen = () => {
        setIsDetailModalOpen(false);
    }

    const closeIsContactManagementModalOpen = () => {
        setIsContactManagementOpen(false);
    }

    const closeIsBrandManagementModalOpen = () => {
        setIsBrandManagementOpen(false);
    }

    const closeMenuDetailModalOpen = () => {
        setIsMenuDetailOpen(false);
    }

    const findCountryByCode = (code: string) => {
        return countries.find((record) => {
            return record.code === code;
        });
    }

    const updateClient = async (id: number, name: string, currencyCode: string, taxes: number, description: string | null, hasLogoChanged: boolean, logo?: File | null) => {
        const response = await CompanyService.updateByClientProfile(id, {
            name,
            currencyCode: currencyCode,
            taxRate: taxes,
            description,
            uploadLogo: hasLogoChanged
        }, logo);

        if(response.success) {
            await fetchData(1);
            messageApi.success(<span>Se actualizó el cliente <b>{name}</b> con ID <b>{id}</b> de manera satisfactoria.</span>, 3.5);
            setIsDetailModalOpen(false);
        }else {
            messageApi.error(response.data.message as string || 'Hubo un error al intentar actualizar al cliente, por favor inténtalo nuevamente.', 3.5);
        }
    }

    const associateCompanyAndMenus = async (company: Company, keys: string[]) => {
        const response = await MenuService.addMenusToCompany({
            companyId: company.id,
            codes: keys
        });

        if(response.success) {
            messageApi.success(<span>Se actualizó la visibilidad de los menus a los cuales tiene acceso el cliente: <b>{company.name}</b> de manera satisfactoria.</span>, 3.5);
            closeMenuDetailModalOpen();
        }else {
            messageApi.error(response.data.message as string || 'Hubo un error al intentar crear al cliente, por favor inténtalo nuevamente.', 3.5);
        }
    }

    return (
        <Layout breadcrumb={[
            { title: <span><SettingOutlined /> Ajustes</span> },
            { title: 'Clientes' }
        ]}
        >
            { contextModalHolder }
            { contextHolder }

            <Card
                loading={loading}
            >
                <div className="filter-container">
                    <Row gutter={[24, 16]}>
                        <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                            <label className="filter-search-container">Filtrar por: <Search placeholder="Nombre de cliente o contacto" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                            <Button type="primary" onClick={showNewClientModalOpen}  disabled={tableLoading}><PlusOutlined /> Crear cliente</Button>
                        </Col>
                    </Row>
                </div>
            </Card>

            <Card
                style={{ marginTop: '18px' }}
                loading={loading}
            >
                <Table
                    <Company>
                    dataSource={dataSource}
                    bordered
                    loading={tableLoading}
                    pagination={tableParams.pagination}
                    size="small"
                    scroll={{ y: `CALC(100VH - 390px)`, x: 768 }}
                    rowKey={(record) => { return record.id }}
                    onChange={handleTableChange}
                >
                    <Column width={60} fixed="left" align="center" title="ID" dataIndex="id" key="id"/>

                    <Column width={240} ellipsis title="Nombre del cliente" key="name" render={(row: Company) => (
                        <>{row.name}</>
                    )}/>

                    <Column width={360} ellipsis title="Contacto" key="contact" render={(row: Company) => (
                        <>{row.contacts!.map((record) => `${record.name} ${record.lastname}`).join(', ')}</>
                    )}/>

                    <Column width={70} align="center" title="País" key="country" render={(row: Company) => (
                        <>{findCountryByCode(row.countryCode)?.name || 'No encontrado'}</>
                    )}/>

                    <Column width={60} fixed="right" align="center" title="" key="actions" render={(row) => (
                        <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                            <Button size="small"><MoreOutlined /></Button>
                        </Dropdown>
                    )} />
                </Table>
            </Card>

            {/* Modals */}
            <NewClientModal
                isOpen={isNewClientModalOpen}
                handleModalCancel={closeNewClientModalOpen}
                submit={createNewClient}
                countries={countries}
            />
            {
                selectedRow && (
                    <DetailClientModal
                        id={selectedRow.id}
                        isOpen={isDetailModalOpen}
                        handleModalCancel={closeDetailClientModalOpen}
                        submit={updateClient}
                        countries={countries}/>
                )
            }

            {
                selectedRow && (
                    <ContactManagementModal
                        parent={selectedRow}
                        isOpen={isContactManagementOpen}
                        handleModalCancel={closeIsContactManagementModalOpen}
                        reloadGrid={fetchData}
                        countries={countries}
                    />
                )
            }

            {
                selectedRow && (
                    <BrandManagementModal
                        parent={selectedRow}
                        isOpen={isBrandManagementOpen}
                        handleModalCancel={closeIsBrandManagementModalOpen}
                    />
                )
            }

            {
                selectedRow && (
                    <MenuDetailModal
                        client={selectedRow}
                        isOpen={isMenuDetailOpen}
                        handleModalCancel={closeMenuDetailModalOpen}
                        menus={menus}
                        submit={associateCompanyAndMenus}
                    />
                )
            }
        </Layout>
    );
}

export default Client;
