import React, {useEffect, useState} from 'react';
import {CompanyContact} from "../../../../../models/CompanyContact";
import {CompanyContactService} from "../../../../../services/CompanyContactService";
import {Button, Col, Divider, Dropdown, MenuProps, message, Modal, Row, Table, TableProps, Tag} from "antd";
import {Company} from "../../../../../models/Company";
import {TableParams} from "../../../../../models/TableParams";
import {PAGE_SIZE} from "../../../../../config/Constants";
import {FunctionsHelper} from "../../../../../utils/FunctionsHelper";
import Column from "antd/es/table/Column";
import {
    DeleteOutlined,
    EyeOutlined,
    MoreOutlined,
    PlusOutlined, TeamOutlined,
} from "@ant-design/icons";
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/es/input";
import NewContactModal from "./NewContactModal";
import {Country} from "../../../../../models/Country";
import DetailContactModal from "./DetailContactModal";

interface ContactManagementModalProps {
    parent: Company;
    isOpen: boolean;
    handleModalCancel: () => void;
    reloadGrid: (currentPage?: number) => void;
    countries: Country[];
}

function ContactManagementModal({ parent, isOpen, handleModalCancel, reloadGrid, countries }: ContactManagementModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<CompanyContact[]>([]);
    const [filteredDataSource, setFilteredDataSource] = useState<CompanyContact[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: undefined,
        sortOrder: undefined
    });

    /* Filters */
    const [term, setTerm] = useState('');

    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<CompanyContact | undefined>(undefined);

    useEffect(() => {
        fetchData(tableParams.pagination?.current);
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    useEffect(() => {
        if(isOpen) {
            fetchDataFromServer(true);
        }else {
            setLoading(false);
            setFilteredDataSource([]);
            setDataSource([]);
        }
    }, [isOpen]);

    const fetchDataFromServer = async (withLoading: boolean = false) => {
        if(withLoading) {
            setLoading(true);
        }
        const response = await CompanyContactService.getByCompany(parent.id);

        if(response.success) {
            setDataSource(response.data);
            fetchData(1, response.data);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la compañía, por favor inténtalo nuevamente.', 3.5);
            handleModalCancel();
        }

        if(withLoading) {
            setLoading(false);
        }
    }

    const fetchData = (currentPage: number = 1, data?: CompanyContact[]) => {
        const termFilter = FunctionsHelper.normalizeText(term.trim());

        let filteredData  = data !== undefined ? data : [...dataSource];

        filteredData = filteredData.sort((a, b) => {
            return b.id - a.id;
        });

        if(termFilter.length > 0) {
            filteredData  = filteredData.filter((record) => {
                const nameAndLastName = FunctionsHelper.normalizeText(`${record.name} ${record.lastname}`);
                const email = FunctionsHelper.normalizeText(record.email);
                return nameAndLastName.includes(termFilter) || email.includes(termFilter);
            });
        }

        const pageSize = tableParams.pagination?.pageSize || PAGE_SIZE;
        const total = filteredData.length;
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = filteredData.slice(startIndex, endIndex);

        setFilteredDataSource(paginatedData);
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: currentPage,
                total: total
            }
        });
    }

    const handleTableChange: TableProps<CompanyContact>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setFilteredDataSource([]);
        }
    };

    const getDropdownMenu = (row: CompanyContact): MenuProps['items'] => {
        const menus: MenuProps['items'] = [];

        menus.push({
            key: '1',
            label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver detalle</span>,
            onClick: () => { showDetailModalOpen(row); }
        });

        menus.push({
            key: '2',
            danger: true,
            label: <span><DeleteOutlined style={{ marginRight: '8px' }}/>Eliminar</span>,
            onClick: () => { deleteContact(row); }
        });

        return menus;
    }

    const deleteContact = async (row: CompanyContact) => {
        setLoading(true)
        const response = await CompanyContactService.delete(row.id);

        if(response.success) {
            await Promise.all([
                fetchDataFromServer(),
                reloadGrid()
            ]);
            messageApi.success(`Se eliminó satisfactoriamente el contacto con ID: ${row.id}.`);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener eliminar el contacto, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    const showDetailModalOpen = (row: CompanyContact) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const showRegisterModalOpen = () => {
        setIsRegisterModalOpen(true);
    }

    const closeRegisterModalOpen = () => {
        setIsRegisterModalOpen(false);
    }

    const closeDetailModalOpen = () => {
        setIsDetailModalOpen(false);
    }

    const createNewContact = async (companyId: number, name: string, lastname: string, email: string, dialCode: string, phoneNumber: string, description: string, isDefaultContact: boolean) => {
        setLoading(true);

        const response = await CompanyContactService.create({
            companyId, name, lastname, email, dialCode, phoneNumber, description, isDefaultContact
        });

        if(response.success) {
            await Promise.all([
                fetchDataFromServer(),
                reloadGrid()
            ]);
            setIsRegisterModalOpen(false);
            messageApi.success(`Se creo satisfactoriamente el contacto con ID: ${response.data.createdId}`);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar crear el contacto, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    const updateContact = async (id: number, name: string, lastname: string, email: string, dialCode: string, phoneNumber: string, description: string, isDefaultContact: boolean) => {
        setLoading(true);

        const response = await CompanyContactService.update(id, {
            name, lastname, email, dialCode, phoneNumber, description, isDefaultContact
        });

        if(response.success) {
            await Promise.all([
                fetchDataFromServer(),
                reloadGrid()
            ]);
            setIsDetailModalOpen(false);
            messageApi.success(`Se guardaron los cambios satisfactoriamente del contacto con ID: ${id}`);
        }else {
            const error = response.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar actualizar los datos del contacto, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={<><TeamOutlined/> Contactos {parent.name}</>}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={1200}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <div className="filter-container" style={{ marginBottom: '18px' }}>
                    <Row gutter={[24, 16]}>
                        <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                            <label className="filter-search-container">Filtrar por: <Search placeholder="Nombres o correo" onSearch={onSearch} disabled={loading} allowClear/></label>
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                            <Button type="primary" onClick={showRegisterModalOpen}  disabled={loading}><PlusOutlined /> Crear contacto</Button>
                        </Col>
                    </Row>
                </div>

                <Table
                    <CompanyContact>
                    dataSource={filteredDataSource}
                    bordered
                    loading={loading}
                    pagination={tableParams.pagination}
                    size="small"
                    scroll={{ x: 768 }}
                    rowKey={(record) => { return record.id }}
                    onChange={handleTableChange}
                >
                    <Column width={60} fixed="left" align="center" title="ID" dataIndex="id" key="id"/>

                    <Column ellipsis width={240} title="Nombres" key="fullname" render={(row: CompanyContact) => (
                        <>{row.name} {row.lastname}</>
                    )}/>

                    <Column ellipsis width={240} title="Correo" key="email" render={(row: CompanyContact) => (
                        <>{row.email}</>
                    )}/>

                    <Column width={80} align="center" title="Tipo" key="type" render={(row: CompanyContact) => (
                        <>
                            {
                                row.isDefaultContact && (
                                    <Tag color="purple">
                                        Principal
                                    </Tag>
                                )
                            }
                        </>
                    )} />

                    <Column width={60} fixed="right" align="center" title="" key="actions" render={(row) => (
                        <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                            <Button size="small"><MoreOutlined /></Button>
                        </Dropdown>
                    )} />
                </Table>
            </Modal>

            {/*Modals*/}
            <NewContactModal
                isOpen={isRegisterModalOpen}
                handleModalCancel={closeRegisterModalOpen}
                parentId={parent.id}
                countryCode={parent.countryCode}
                countries={countries}
                submit={createNewContact}
            />

            {
                selectedRow && (
                    <DetailContactModal
                        isOpen={isDetailModalOpen}
                        id={selectedRow.id}
                        handleModalCancel={closeDetailModalOpen}
                        countryCode={parent.countryCode}
                        countries={countries}
                        submit={updateContact}
                    />
                )
            }
        </>
    );
}

export default ContactManagementModal;
