import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";

export const FileService = {
    getFile: async (key: string, download = false): Promise<ApiResponse<{ fileBlob: Blob | MediaSource, filename: string }>>  => {
        try {
            const response = await MainBackendAxios.get(`/file/get?key=${key}${download ? '&download=true': ''}`, { responseType: 'blob' });

            let filename = FunctionsHelper.generateUniqueId();
            const contentDispositionHeader = response.headers['content-disposition'];
            if (contentDispositionHeader) {
                const matches = contentDispositionHeader.match(/filename="(.*?)"/);
                if (matches && matches[1]) {
                    filename = matches[1];
                }
            }

            return {
                success: true,
                status: response.status,
                data: {
                    filename,
                    fileBlob: response.data
                }
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getFileAsBase64: async (key: string): Promise<ApiResponse<{ base64: string, filename: string }>> => {
        try {
            const fileResponse = await FileService.getFile(key);

            if (!fileResponse.success) {
                return fileResponse;
            }

            const blob = fileResponse.data.fileBlob as Blob;

            const base64Data = await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });

            return {
                success: true,
                status: fileResponse.status,
                data: {
                    base64: base64Data,
                    filename: fileResponse.data.filename
                }
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};
