import React, {useEffect, useState} from 'react';

import './ClientOperationReports.scss';
import {Button, Card, Col, Dropdown, MenuProps, message, Row, Table, TableProps, Tag, Tooltip} from "antd";
import {TableParams} from "../../models/TableParams";
import {PAGE_SIZE} from "../../config/Constants";
import {OperationReport} from "../../models/OperationReport";
import {
    EyeOutlined,
    FileDoneOutlined,
    MoreOutlined,
} from "@ant-design/icons";
import Search from "antd/es/input/Search";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import Layout from "../../components/Layout/Layout";
import {SearchProps} from "antd/es/input";
import {OperationReportService} from "../../services/OperationReportService";

function ClientOperationReports() {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<OperationReport[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: 'createdAt',
        sortOrder: 'descend'
    });

    /* Filters */
    const [term, setTerm] = useState('');

    const [selectedRow, setSelectedRow] = useState<OperationReport | undefined>();

    /* Settings */
    const heightOffset = 164 + 36;
    const heightPadding = 48;
    const vh = window.innerHeight;
    const vw = window.innerWidth;

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    useEffect(() => {
        init();

    }, []);

    const init = async () => {
        setLoading(true);

        await fetchData();

        setLoading(false);
    }

    const fetchData = async (currentPage?: number) => {
        setTableLoading(true);

        const termFilter = term.trim() || undefined;

        const datasourceResponse = await OperationReportService.getAllByCurrentUserCompanyAndFiltersAndPaginate({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            sortField: tableParams.sortField,
            sortOrder: tableParams.sortOrder,
            term: termFilter,
        });

        if(datasourceResponse.success) {
            const data = datasourceResponse.data;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = datasourceResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const handleTableChange: TableProps<OperationReport>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const getDropdownMenu = (row: OperationReport): MenuProps['items'] => {
        return [
            {
                key: '1',
                label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver reporte</span>,
                onClick: () => { setReport(row); }
            },
        ];
    }

    const setReport = (row: OperationReport) => {
        setSelectedRow(row);
    }

    return (
        <Layout breadcrumb={!selectedRow ? [
            { title: <span><FileDoneOutlined /> Operaciones</span> }
        ] : [
            { title: <span><FileDoneOutlined /> Operaciones</span>, href: 'javascript:void(0);', onClick: () => { setSelectedRow(undefined); } },
            { title: selectedRow.name }
        ]}
                title={!selectedRow ? 'Historial de operación' : selectedRow.name}
        >
            { contextHolder }

            {
                !selectedRow && (
                    <>
                        <Card
                            loading={loading}
                        >
                            <div className="filter-container">
                                <Row gutter={[24, 16]}>
                                    <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                                        <label className="filter-search-container">Filtrar por: <Search placeholder="Marca, nombre o etiqueta" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                                    </Col>
                                    <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container"/>
                                </Row>
                            </div>
                        </Card>

                        <Card
                            style={{ marginTop: '18px' }}
                            loading={loading}
                        >
                            <Table
                                <OperationReport>
                                dataSource={dataSource}
                                bordered
                                loading={tableLoading}
                                pagination={tableParams.pagination}
                                size="small"
                                scroll={{ y: `CALC(100VH - 390px)`, x: 768 }}
                                rowKey={(record) => { return record.id }}
                                onChange={handleTableChange}
                            >
                                <Column width={80} fixed="left" align="center" title="ID" dataIndex="id" key="id"/>

                                <Column width={320} ellipsis title="Marca" key="brand" render={(row: OperationReport) => (
                                    <>{row.brand!.name}</>
                                )}/>

                                <Column width={320} ellipsis title="Nombre" key="name" render={(row: OperationReport) => (
                                    <>{row.name}</>
                                )}/>

                                <Column width={320} ellipsis title="Etiquetas" key="tags" render={(row: OperationReport) => (
                                    row.operationTags!.map((record) => {
                                        return (
                                            <Tag>{record.name}</Tag>
                                        );
                                    })
                                )}/>

                                <Column align="center" title="Fecha de creación" key="createdAt" render={(row: OperationReport) => (
                                    <span title={dayjs(row.createdAt).format('DD/MM/YYYY hh:mm A')}>{dayjs(row.createdAt).format('DD/MM/YYYY')}</span>
                                )} sorter={true} defaultSortOrder="descend" width={150} hidden/>

                                <Column width={60} fixed="right" align="center" title="" key="actions" render={(row: OperationReport) => (
                                    <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                                        <Button size="small"><MoreOutlined /></Button>
                                    </Dropdown>
                                )} />
                            </Table>
                        </Card>
                    </>
                )
            }

            {
                selectedRow && (
                    <Card
                        loading={loading}
                        style={{
                            width: '100%',
                            height: `${vh - heightOffset}px`,
                        }}
                    >
                        <iframe
                            src={selectedRow.url}
                            title={"title"}
                            frameBorder={0}
                            style={{
                                marginTop: `0`,
                                width: `100%`,
                                height: `${vh - heightOffset - heightPadding}px`
                            }}
                        />
                    </Card>
                )
            }
        </Layout>
    );
}

export default ClientOperationReports;
