import React, {useEffect, useState} from 'react';
import {Button, Collapse, Form, Popover} from "antd";
import {FilterOutlined} from "@ant-design/icons";

import './FilterContainer.scss';

export interface FilterContainerItem {
    key: string;
    label: string | React.ReactNode;
    element: React.ReactNode;
    isOpen?: boolean;
    // required?: boolean;//o rules
}

interface FilterContainerProps {
    loading?: boolean;
    submit: (values: any) => void;
    width?: number;
    items?: FilterContainerItem[];
    initialValues?: Record<string, any>;
    roles: any[]
}

function FilterContainer({ loading = false, width = 350, submit, items = [], initialValues, roles }: FilterContainerProps) {
    const [form] = Form.useForm();
    const [openFilterPopOver, setOpenFilterPopOver] = useState(false);

    useEffect(() => {
        if(openFilterPopOver && initialValues) {
            Object.entries(initialValues).forEach(([key, value]) => {
                form.setFieldValue(key, value);
            });
        }
    }, [openFilterPopOver]);

    const hideFilterPopOver = () => {
        setOpenFilterPopOver(false);
    };

    const handleOpenFilterPopOverChange = (newOpen: boolean) => {
        setOpenFilterPopOver(newOpen);
    };

    const submitFilter = async (values: any) => {
        hideFilterPopOver();
        submit(values);
    }
    return (
        <Form
            form={form}
            onFinish={submitFilter}
        >
            <Popover
                title="Filtros adicionales"
                placement="bottom"
                trigger="click"
                open={openFilterPopOver}
                onOpenChange={handleOpenFilterPopOverChange}
                content={(
                    <div
                        style={{ width: `${width}px`, display: 'flex', flexDirection: 'column', gap: '8px' }}
                    >
                        {
                            items.map((item) => (
                                <Collapse key={`filter-${item.key}`} size="small" items={[
                                    {
                                        key: item.key,
                                        label: item.label,
                                        children: (
                                            <Form.Item name={item.key} style={{ marginBottom: '0', width: '100%' }}>
                                                {item.element}
                                            </Form.Item>
                                        )
                                    }
                                ]} defaultActiveKey={item.isOpen ? [ item.key ] : undefined } />
                            ))
                        }

                        <Button onClick={() => { form.submit(); }} type="primary" style={{ width: '100%' }} disabled={loading}><FilterOutlined /> Aplicar filtro</Button>
                    </div>
                )}
            >
                <Button type="primary" disabled={loading}><FilterOutlined /></Button>
            </Popover>
        </Form>
    );
}

export default FilterContainer;
