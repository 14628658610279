import MainBackendAxios from '../utils/MainBackendAxios';
import { User } from '../models/User';
import { LoginDto } from '../models/dtos/LoginDto';
import {ApiResponse} from "../models/ApiResponse";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {getFormattedUserData} from "../utils/AuthUtils";

export const AuthService = {
  login: async (loginParams: LoginDto): Promise<ApiResponse<User>>  => {
    try {
      const response = await MainBackendAxios.post(`/auth/login`, loginParams);
      const userData = response?.data?.data;
      return {
        success: true,
        status: response.status,
        data: getFormattedUserData(userData) as User,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  verifySession: async (): Promise<ApiResponse<User>> => {
    try {
      const response = await MainBackendAxios.get(`/auth/current-user`);

      const userData = response.data.data;

      return {
        success: true,
        status: response.status,
        data: getFormattedUserData(userData) as User,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  logout: async (): Promise<ApiResponse<{ executedAction: boolean }>> => {
    try {
      const response = await MainBackendAxios.post("/auth/logout");

      return {
        success: true,
        status: response.status,
        data: response.data?.data
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};
