import React, {useEffect, useState} from 'react';
import {RequestActivation} from "../../../../../models/RequestActivation";
import {Button, Table} from "antd";
import { FilterOutlined} from "@ant-design/icons";
import {Activation} from "../../../../../models/Activation";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/es/input";
import {FunctionsHelper} from "../../../../../utils/FunctionsHelper";

interface BcScheduleTabProps {
    requestActivation: RequestActivation
}

function BcScheduleTab({ requestActivation }: BcScheduleTabProps) {
    const [filteredDataSource, setFilteredDataSource] = useState<Activation[]>([...(requestActivation.activations || [])]);


    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        const activations = [...(requestActivation.activations || [])];
        const term = FunctionsHelper.normalizeText(value.trim());

        if(term.length === 0) {
            setFilteredDataSource(activations);
        }else {
            const localFilteredDataSource = activations.filter((record) => {
                return FunctionsHelper.normalizeText(record.store?.code).includes(term) || FunctionsHelper.normalizeText(record.store?.name).includes(term)
            });
            setFilteredDataSource(localFilteredDataSource);
        }
    };

    return (
        <div className="bc-request-activation-details-schedule-tab">
            <div className="filter-container">
                <label>Filtrar por:</label>
                <Search placeholder="Punto de venta o código" onSearch={onSearch} allowClear />
                <Button type="primary" style={{ float: 'left' }}><FilterOutlined /></Button>
            </div>

            <Table
                <Activation>
                dataSource={filteredDataSource}
                pagination={false}
                size="small"
                scroll={{ x: 'auto'}}
                style={{ marginTop: '15px' }}
                rowKey={(record) => { return record.id }}
                bordered
            >
                <Column title="Código" key="code" render={(row: Activation) => (
                    <>{ row.store!.code }</>
                )}/>

                <Column title="PDV" key="store" render={(row: Activation) => (
                    <>{ row.store!.name }</>
                )}/>

                <Column title="Dirección PDV" key="address" render={(row: Activation) => (
                    <>{ row.store!.address }</>
                )}/>

                <Column title="Fechas" key="dates" render={(row: Activation) => (
                    <>{ `${dayjs(row.dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')} - ${dayjs(row.dates[row.dates.length - 1], 'YYYY-MM-DD').format('DD/MM/YYYY')}` }</>
                )}/>

                <Column title="Días" key="days" render={(row: Activation) => (
                    <>{ row.dates.length }</>
                )}/>

                <Column title="Horarios" key="schedules" render={(row: Activation) => (
                    <>{ row.availableSchedule?.label }</>
                )}/>

                <Column title="Colaboradores" key="numberOfCollaborators" render={(row: Activation) => (
                    <>{ row.numberOfCollaborators }</>
                )}/>
            </Table>
        </div>
    );
}

export default BcScheduleTab;
