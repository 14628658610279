import { combineReducers } from 'redux';
import { createRouterReducer } from '@lagunovsky/redux-react-router';
import AuthReducer from './AuthReducer';
import MenuReducer from "./MenuReducer";

const rootReducer = (history: any) =>
  combineReducers({
    auth: AuthReducer,
    menu: MenuReducer,
    router: createRouterReducer(history),
  });

export default rootReducer;
