import React from 'react';
import {Card, Col, Row} from "antd";

function RequestActivationDetailModalSkeleton() {
    const staticHeight = '65vh';
    return (
        <Row gutter={24}>
            <Col xs={24} md={24} lg={6} xl={6}  xxl={6} style={{ marginBottom: '15px' }}>
                <Card loading={true} style={{ height: staticHeight }}/>
            </Col>

            <Col xs={24} md={24} lg={18} xl={18} xxl={18} style={{ marginBottom: '15px' }}>
                <Card loading={true} style={{ height: staticHeight }}/>
            </Col>
        </Row>
    );
}

export default RequestActivationDetailModalSkeleton;
