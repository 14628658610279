import React, {useEffect, useState} from 'react';
import {useThemeLayoutConfig} from "../../config/ThemeDashboardLayoutConfig";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {Button, Checkbox, ConfigProvider, Flex, Form, Input, message, Modal, Popconfirm, Typography} from "antd";
import {UserService} from "../../services/UserService";
import {User} from "../../models/User";
import AuthAction from "../../redux/actions/authActions/Actions";
import esES from "antd/locale/es_ES";
import Password from "antd/es/input/Password";

import './PasswordRecovery.scss';

function PasswordRecovery() {
    const themeConfig = useThemeLayoutConfig();
    const dispatch = useDispatch();
    const { token }: any = useParams();
    const [loading, setLoading] = useState(true);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        messageApi.open({
            key: 'init-password-recovery',
            type: 'loading',
            content: 'Validando token de recuperación...',
            duration: 0
        });
        const tokenResponse = await UserService.verifyRecoveryToken(token);

        if(tokenResponse.success) {
            const tokenData = tokenResponse.data as { userId: number, email: string };
            form.setFieldsValue({
                username: tokenData.email,
            });
            setLoading(false);
            messageApi.destroy();
        }else {
            const error = tokenResponse.data;
            const errorMessage = error.message as string || 'Hubo un error al intentar obtener datos del token enviado, por favor inténtalo nuevamente.';
            messageApi.destroy();
            messageApi.error(errorMessage, 20);
        }
    }

    const onFinish = async (values: any) => {
        if(!loading) {
            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Restableciendo contraseña...',
                duration: 0
            });
            setLoading(true);

            const updatePasswordResponse = await UserService.updatePasswordByRecoveryToken(token, values.password);

            messageApi.destroy(loadingMessageKey);

            if(updatePasswordResponse.success) {
                const data = updatePasswordResponse.data as { executedAction: boolean, user: User };
                messageApi.success('La contraseña fue restablecida con satisfactoriamente.', 3.5);
                const currentUser = data.user as User;
                dispatch(AuthAction.signIn(currentUser));
            }else{
                const error = updatePasswordResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar restablecer la contraseña, por favor inténtalo nuevamente.', 3.5);
            }

            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };

    return (
        <ConfigProvider locale={esES} theme={themeConfig}>

            {contextHolder}

            <main className="password-recovery-main-container">
                <div className="password-recovery-main-container-children">
                    <Form
                        name="password-recovery-form"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        className="password-recovery-form-container"
                    >
                        <div className="password-recovery-main-logo-container">
                            <img className="password-recovery-logo-img" src="/logofull.png" alt="logo"/>
                        </div>

                        <Typography className="password-recovery-main-title" style={{ color: themeConfig.token?.colorPrimary, fontWeight: 600, fontSize: '20px' }}>
                            Restablece tu contraseña
                        </Typography>

                        <Form.Item
                        >
                            <Input
                                placeholder="Usuario"
                                disabled={true}
                                value={form.getFieldValue('username')}
                                style={{ fontWeight: 'bold' }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: 'La nueva contraseña es requerida' },
                                { min: 4, message: 'La contraseña debe de tener un mínimo de 4 caracteres' },
                            ]}
                        >
                            <Password
                                placeholder="Nueva contraseña"
                            />
                        </Form.Item>

                        <Form.Item
                            name="repassword"
                            rules={[
                                { required: true, message: 'Debe de ingresar nuevamente la nueva contraseña' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Las contraseñas no coinciden'));
                                    },
                                }),
                            ]}
                        >
                            <Password
                                placeholder="Repetir nueva contraseña"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading} block>
                                RESTABLECER CONTRASEÑA
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </main>
        </ConfigProvider>
    );
}

export default PasswordRecovery;
