import React from 'react';
import { useSelector } from 'react-redux';
import { User } from '../../models/User';
import Layout from "../../components/Layout/Layout";
import {useThemeLayoutConfig} from "../../config/ThemeDashboardLayoutConfig";
import moment from "moment/moment";
import 'moment/locale/es';
import {FunctionsHelper} from "../../utils/FunctionsHelper";

import './Home.scss';

function Home() {
  const themeConfig = useThemeLayoutConfig();
  const user: User = useSelector((state: any) => state.auth);
  const currentDate = moment();


  return (
      <Layout>
        <div className="home-container">
            <span className="home-subtitle" style={{ fontFamily: themeConfig.token?.fontFamily, lineHeight: themeConfig.token?.lineHeight, color: themeConfig.token?.colorText }}>{FunctionsHelper.capitalizeFirstLetter(currentDate.format('dddd D [de] MMMM [del] YYYY'))}</span>
            <h1 className="home-title" style={{ color: themeConfig.token?.colorPrimary, fontFamily: themeConfig.token?.fontFamily, lineHeight: themeConfig.token?.lineHeight }}>Buen día {user.name}</h1>
        </div>
      </Layout>
  );
}

export default Home;
