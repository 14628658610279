import React from 'react';

import './StatusIndicator.scss';

interface StatusIndicatorProps {
    text: string;
    color: string;
}

function StatusIndicator({ text, color }: StatusIndicatorProps) {
    return (
        <span
            style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
        >
            <span
                style={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: color,
                    display: 'inline-block',
                    marginRight: 8,
                }}
            ></span>
            {text}
        </span>
    );
}

export default StatusIndicator;
