import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {ReactComponent as PencilIcon } from "../../../../assets/icons/pencil.svg";

import {
    MinChatUiProvider,
    MainContainer,
    MessageInput,
    MessageContainer,
    MessageList,
} from "@minchat/react-chat-ui";
import MessageType from "@minchat/react-chat-ui/dist/types/MessageType";
import {message, Typography} from "antd";
import {RequestActivation} from "../../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../../config/ThemeDashboardLayoutConfig";
import {User} from "../../../../models/User";
import {RequestActivationLogService} from "../../../../services/RequestActivationLogService";
import {RequestActivationLog} from "../../../../models/RequestActivationLog";
import {FunctionsHelper} from "../../../../utils/FunctionsHelper";

interface AdminBusinessChainNoteTabProps {
    requestActivation: RequestActivation
}

function AdminClientNoteTab({ requestActivation }: AdminBusinessChainNoteTabProps) {
    const themeConfig = useThemeLayoutConfig();
    const [messageApi, contextHolder] = message.useMessage();
    const authUser: User = useSelector((state: any) => state.auth);
    const staticHeight = '65vh';
    const offset = 50;
    const [messages, setMessages] = useState<MessageType[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);

    const colorSet = {
        "--container-background-color": "white",
        "--loader-color": themeConfig.token!.colorPrimary,
        "--messagelist-background-color": "white",
        "--input-background-color": "white",
        "--input-element-color": "#FFFFFF",
        "--input-placeholder-color": "#707070",
        "--input-text-color": "#000000",
        "--incoming-message-text-color": "#707070",
        "--incoming-message-background-color": themeConfig.components!.Menu!.itemActiveBg,
        "--incoming-message-timestamp-color": "#707070",
        "--incoming-message-link-color": "#FF0000",
        "--outgoing-message-text-color": themeConfig.components!.Menu!.itemActiveBg,
        "--outgoing-message-background-color": themeConfig.token!.colorPrimary,
        "--outgoing-message-timestamp-color": themeConfig.components!.Menu!.itemActiveBg,
        "--outgoing-message-checkmark-color": themeConfig.components!.Menu!.itemActiveBg,
        "--outgoing-message-loader-color": themeConfig.components!.Menu!.itemActiveBg,
        "--outgoing-message-link-color": 'gold',
    };

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
      setLoading(true);

      const requestActivationLogResponse = await RequestActivationLogService.getByRequestActivation(requestActivation.id, { requestActivationStatusId: 'NOTE_PER_BUSINESS_CHAIN' });

      if(requestActivationLogResponse.success) {
          fetchMessages(requestActivationLogResponse.data);
          setLoading(false);
      }else {
          const error = requestActivationLogResponse.data;
          messageApi.error(error.message as string || 'Hubo un error al intentar obtener las notas de la solicitud, por favor inténtalo nuevamente.', 3.5);
      }
    };

    const fetchMessages = (notes: RequestActivationLog[]) => {
        setMessages(
            notes
                .sort((a, b) => {
                    return a.id - b.id;
                })
                .map((record) => {
                    const createdAt = new Date(record.createdAt);
                    const now = new Date();

                    const timeDifferenceInMinutes = (now.getTime() - createdAt.getTime()) / (1000 * 60);

                    return {
                        text: record.additionalData.content,
                        createdAt: timeDifferenceInMinutes < 2 ? undefined : createdAt,
                        user: {
                            id: `${record.user!.id}`,
                            name: `${record.user!.name} ${record.user!.lastname}`,
                            avatar: FunctionsHelper.getAvatarUi(themeConfig, record.user!.name, record.user!.lastname)
                        }
                    };
                })
        );
    }

    const sendMessage = async (content: string) => {
        const localMessages = [...messages];
        localMessages.push({
            text: content,
            // createdAt: dayjs().toDate(),
            loading: true,
            user: {
                id:  `${authUser.id}`,
                name: `${authUser.name} ${authUser.lastname}`,
                avatar: FunctionsHelper.getAvatarUi(themeConfig, authUser.name, authUser.lastname),
            },
        });
        setMessages(localMessages);

        setLoadingMessage(true);

        const newNoteResponse = await RequestActivationLogService.createBusinessChainNote(requestActivation.id, { content });

        setLoadingMessage(false);

        if(newNoteResponse.success) {
            const requestActivationLogResponse = await RequestActivationLogService.getByRequestActivation(requestActivation.id, { requestActivationStatusId: 'NOTE_PER_BUSINESS_CHAIN' });

            if(requestActivationLogResponse.success) {
                fetchMessages(requestActivationLogResponse.data);
            }else {
                const error = requestActivationLogResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener las notas de la solicitud, por favor inténtalo nuevamente.', 3.5);
            }
        }else {
            const error = newNoteResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar guardar la nota enviada, por favor inténtalo nuevamente.', 3.5);
            localMessages.pop();
            setMessages(localMessages);
        }

    }

    return (
        <>
            { contextHolder }

            <MinChatUiProvider theme={themeConfig.token?.colorPrimary} colorSet={colorSet}>
                <MainContainer style={{ height: `CALC(${staticHeight} - ${offset}px)` }}>
                    <MessageContainer>
                        {
                            loading && (
                                <MessageList
                                    loading={loading}
                                    currentUserId={`${authUser.id}`}
                                    messages={messages}
                                    customEmptyMessagesComponent={(
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '15px' }}>
                                                <PencilIcon style={{ stroke: '#585858' }} />
                                                <Typography style={{ color: '#585858' }}>Aún no se han registrado notas.</Typography>
                                            </div>
                                        </div>
                                    )}
                                />
                            )
                        }
                        {
                            !loading && (
                                <MessageList
                                    currentUserId={`${authUser.id}`}
                                    messages={messages}
                                    customEmptyMessagesComponent={(
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '15px' }}>
                                                <PencilIcon style={{ stroke: '#585858' }} />
                                                <Typography style={{ color: '#585858' }}>Aún no se han registrado notas.</Typography>
                                            </div>
                                        </div>
                                    )}
                                />
                            )
                        }
                        <MessageInput
                            placeholder="Escribe una nota para ayudarte ante cualquier duda de tu solicitud."
                            showSendButton
                            disabled={loadingMessage || loading}
                            showAttachButton={false}
                            onSendMessage={sendMessage}
                        />
                    </MessageContainer>
                </MainContainer>
            </MinChatUiProvider>
        </>
    );
}

export default AdminClientNoteTab;
